import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import EditorialInformation from "./EditorialInformation";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useIntParams from "shared/hooks/useIntParams";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ArtistManagementContext } from "pages/ArtistManagement";

const schema = yup.object().shape({
    born: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null)
        .matches(
            /^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/,
            "utils.form.date_format"
        )
        .when("died", {
            is: (died) => died,
            then: () => yup.string().nullable().required("utils.form.required"),
        }),
    debut_date: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null)
        .matches(
            /^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/,
            "utils.form.date_format"
        ),
    birth_country: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    birth_area: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    birth_city: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    activity_period: yup
        .array()
        .of(
            yup.object().shape({
                start_date: yup
                    .string()
                    .trim()
                    .required("utils.form.required")
                    .matches(
                        /^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/,
                        "utils.form.date_format"
                    ),
                end_date: yup
                    .string()
                    .nullable()
                    .trim()
                    .transform((value) => value || null)
                    .matches(
                        /^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/,
                        "utils.form.date_format"
                    )
                    .test(
                        "is-greater-than-start",
                        "utils.form.end_date_greater_than_start",
                        function (endDate, context) {
                            const startDate = context.parent.start_date;
                            return (
                                !startDate || !endDate || endDate >= startDate
                            );
                        }
                    ),
            })
        )
        .ensure(),
    died: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null)
        .matches(
            /^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/,
            "utils.form.date_format"
        ),
    death_country: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    death_area: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    death_city: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
});

const EnhancedEditorialInformation = (props) => {
    const { api } = useAPI();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artist } = useContext(ArtistManagementContext);
    const { artistId } = useIntParams();
    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            born: null,
            debut_date: null,
            birth_country: null,
            birth_area: null,
            birth_city: null,
            activity_period: [],
            died: null,
            death_country: null,
            death_area: null,
            death_city: null,
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "activity_period",
    });

    const getEditorialInformation = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/editorial/information`, { locale })
            .then((response) => {
                if (!cancelled) {
                    reset(response);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId, locale]);

    useEffect(getEditorialInformation, [getEditorialInformation]);

    const onSubmit = async (data) => {
        await api
            .put(`artist/${artistId}/editorial/information`, { locale }, data)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.editorialInformation.update.success`
                    )
                );
                reset(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.editorialInformation.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <EditorialInformation
            {...props}
            getEditorialInformation={getEditorialInformation}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            location={location}
            artistType={artist.type}
            fields={fields}
            append={append}
            remove={remove}
        />
    );
};

export default EnhancedEditorialInformation;
