import React, { useContext, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router";
import { DiscographyContext } from "pages/ArtistManagement/Discography";
import Create from "./Create";

const EnhancedCreate = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { releases } = useContext(DiscographyContext);
    const { selectedTab, artistId, albumId, locale } = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSuccess = (releaseId) => {
        handleClose();
        navigate(
            `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${releaseId}`
        );
    };

    return (
        <Create
            {...props}
            artistId={artistId}
            albumId={albumId}
            defaultMain={releases.length == 0}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            onSuccess={onSuccess}
            location={location}
        />
    );
};

export default EnhancedCreate;
