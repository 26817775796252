import React from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import PartnerTracks from "pages/ArtistManagement/Discography/Sandbox/PartnerTracks";

const CopyProductModal = ({
    show,
    isLoading,
    isSubmittingAlbum,
    isSubmittingRelease,
    partnerTracks,
    albumId,
    onClose,
    onCopyAsAlbum,
    onCopyAsRelease,
}) => {
    return (
        <Modal size="lg" show={show} onHide={onClose} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n t="artist.management.disco.sandbox.copyProduct.head" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                {isLoading ? (
                    <Loader className="h-100 align-items-center" size="3x" />
                ) : (
                    <PartnerTracks tracks={partnerTracks} />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={onCopyAsAlbum}
                    disabled={isLoading || isSubmittingAlbum}
                >
                    {isSubmittingAlbum ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t="artist.management.disco.sandbox.copyProduct.copyAsAlbum.title" />
                    )}
                </Button>
                <Button
                    variant="primary"
                    onClick={onCopyAsRelease}
                    disabled={isLoading || isSubmittingRelease || !albumId}
                >
                    {isSubmittingRelease ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t="artist.management.disco.sandbox.copyProduct.copyAsRelease.title" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CopyProductModal;
