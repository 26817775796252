import React from "react";
import { Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import EditMatchingsForm from "shared/components/EditMatchingsForm";

const EditMatchingsModal = ({
    artistId,
    artistName,
    matched,
    show,
    onHide,
    onChange,
    fetchCandidates,
    locale,
}) => {
    return (
        <Modal show={show} onHide={onHide} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{artistName}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <table className="table table-bordered table-sm border-left-0 border-right-0">
                    <tbody>
                        <tr
                            className={
                                matched ? "table-success" : "table-danger"
                            }
                        >
                            <td className="text-center border-left-0 border-right-0">
                                <span>
                                    <strong className="mr-1">
                                        <I18n t="common.editMatchingsModal.status" />
                                    </strong>
                                    <I18n
                                        t={
                                            matched
                                                ? "common.editMatchingsModal.matched"
                                                : "common.editMatchingsModal.notMatched"
                                        }
                                    />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Body className="d-flex flex-column overflow-hidden p-0">
                <EditMatchingsForm
                    artistId={artistId}
                    onChange={onChange}
                    fetchCandidates={fetchCandidates}
                    locale={locale}
                />
            </Modal.Body>
        </Modal>
    );
};

export default EditMatchingsModal;
