import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const Data = ({ stats, isLoading }) => {
    return isLoading ? (
        <Loader className="mt-3 mb-3" />
    ) : (
        <table className="table table-hover table-bordered">
            <tbody>
                <tr>
                    <td className="font-weight-bold">
                        <I18n t="moodmedia.admin.total" />
                    </td>
                    <td>{stats.total}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">
                        <I18n t="moodmedia.admin.morethan_500_similar" />
                    </td>
                    <td>{stats.morethan_500_similar}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">
                        <I18n t="moodmedia.admin.to_50_499_similar" />
                    </td>
                    <td>{stats.to_50_499_similar}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">
                        <I18n t="moodmedia.admin.to_2_49_similar" />
                    </td>
                    <td>{stats.to_2_49_similar}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">
                        <I18n t="moodmedia.admin.no_similar" />
                    </td>
                    <td>{stats.no_similar}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default Data;
