import React from "react";
import Widget from "shared/components/Widget";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
);

const Historic = ({ isLoading, options, data }) => {
    return (
        <Widget title="api.monitoring.historic.head" isLoading={isLoading}>
            <Widget.Body className="d-flex justify-content-center">
                <Line options={options} data={data} />
            </Widget.Body>
        </Widget>
    );
};

export default Historic;
