import React, { useState, useEffect } from "react";
import ImageCard from "./ImageCard";

const EnhancedImageCard = ({ url, ...props }) => {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    useEffect(() => {
        if (url) {
            const fillDimensions = function () {
                setWidth(this.naturalWidth);
                setHeight(this.naturalHeight);
            };

            let img = new Image();

            img.addEventListener("load", fillDimensions);
            img.src = url;

            return () => {
                img.removeEventListener("load", fillDimensions);
            };
        }
    }, [url]);

    return <ImageCard url={url} width={width} height={height} {...props} />;
};

export default EnhancedImageCard;
