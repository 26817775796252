import React from "react";
import SubTask from "./SubTask";
import { Link } from "shared/lib/I18n";

const Task = ({ task, handleStatusChanged }) => {
    return (
        <tr>
            <td>
                {task.artist_name ? (
                    <Link
                        to={`/apple/sample/assessment/${task.lyrics_sample_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {task.artist_name}
                    </Link>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.album_title ? (
                    <Link
                        to={`/apple/sample/assessment/${task.lyrics_sample_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {task.album_title}
                    </Link>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.track_title ? (
                    <Link
                        to={`/apple/sample/assessment/${task.lyrics_sample_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {task.track_title}
                    </Link>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.category || task.reason ? (
                    <span className="badge badge-info">
                        {[task.category, task.reason]
                            .filter(Boolean)
                            .join(" - ")}
                    </span>
                ) : (
                    "-"
                )}
            </td>
            <td>
                {task.subtasks.map((subtask) => (
                    <SubTask
                        key={subtask.id}
                        task={task}
                        subtask={subtask}
                        handleStatusChanged={handleStatusChanged}
                    />
                ))}
            </td>
        </tr>
    );
};

export default Task;
