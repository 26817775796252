import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import Data from "./Data";

const Statistics = ({ show, handleClose, handleShow, ...props }) => {
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <I18n t="moodmedia.admin.statistics" />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="moodmedia.admin.statistics" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Data {...props} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Statistics;
