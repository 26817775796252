import React from "react";
import Loader from "shared/components/Loader";
import I18n from "shared/lib/I18n";
import PlanningEditorial from "./PlanningEditorial";
import PlanningApple from "./PlanningApple";
import logo from "assets/static/images/MusicStory_Symbol_RGB.png";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const PlanningsProduction = ({ plannings, isLoading }) => {
    let planningList = (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "calc(100% - 1.5rem - 18px)" }}
        >
            <div>
                <div>
                    <img style={{ width: "325px" }} src={logo} alt="logo" />
                </div>
                <div>
                    <h2 className="mt-3 text-center">
                        <strong>
                            <I18n t="logo" />
                        </strong>
                    </h2>
                </div>
            </div>
        </div>
    );

    if (plannings.length !== 0) {
        planningList = plannings.map((planning) => {
            switch (planning.partner_id) {
                case PLANNING_PARTNER.EDITORIAL:
                    return (
                        <PlanningEditorial
                            key={`${planning.id}-${planning.partner_id}`}
                            planning={planning}
                        />
                    );
                case PLANNING_PARTNER.APPLE:
                    return (
                        <PlanningApple
                            key={`${planning.id}-${planning.partner_id}`}
                            planning={planning}
                        />
                    );
                default:
                    console.error(
                        `Planning partner unknown : ${planning.partner_id}`
                    );
            }
        });
    }

    return (
        <main
            id="content"
            className="content"
            role="main"
            style={plannings.length === 0 ? { height: "100%" } : undefined}
        >
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <I18n t="dashboard" />
                </li>
            </ol>
            {isLoading ? <Loader /> : planningList}
        </main>
    );
};

export default PlanningsProduction;
