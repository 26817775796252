import React from "react";
import I18n from "shared/lib/I18n";
import { Helmet } from "react-helmet";
import ApiToken from "./ApiToken";
import Status from "./Status";
import Historic from "./Historic";

const ApiMonitoring = ({ location, remaining, setRemaining }) => {
    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(
                    location,
                    "dashboard"
                )} - ${I18n.getTranslation(
                    location,
                    "api.breadcrumb"
                )} - ${I18n.getTranslation(
                    location,
                    "api.monitoring.breadcrumb"
                )}`}</title>
            </Helmet>
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="api.breadcrumb" />
                    </li>
                    <li className="breadcrumb-item active">
                        <I18n t="api.monitoring.breadcrumb" />
                    </li>
                </ol>

                <div className="row mb-4">
                    <div className="col-8">
                        <Status setRemaining={setRemaining} />
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <ApiToken />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Historic remaining={remaining} />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ApiMonitoring;
