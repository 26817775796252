import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import "@musicstory/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useLocation } from "react-router";
import Widget from "shared/components/Widget";
import Statistics from "./Statistics";
import "./Admin.css";
import InputSelect from "shared/components/Form/InputSelect/InputSelect.container";

const Admin = ({
    catalog,
    selectedCatalogOptions,
    setSelectedCatalogOptions,
    columns,
    isLoading,
    options,
    catalogOptions,
    defaultSorted,
}) => {
    const { SearchBar } = Search;
    const location = useLocation();

    return (
        <ToolkitProvider keyField="id" data={catalog} columns={columns} search>
            {(toolkitprops) => (
                <Widget
                    actions={
                        <div style={{ width: 100 + "px" }}>
                            <InputSelect
                                onChange={(opt) => {
                                    setSelectedCatalogOptions(
                                        opt ? opt.value : null
                                    );
                                }}
                                placeholder={
                                    <I18n t="moodmedia.recommendation.catalog" />
                                }
                                value={{
                                    value: selectedCatalogOptions,
                                    label: selectedCatalogOptions,
                                }}
                                options={catalogOptions}
                            />
                        </div>
                    }
                >
                    <Widget.Body>
                        <div className="row mb-3">
                            <div className="col-11">
                                <SearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder={I18n.getTranslation(
                                        location,
                                        "moodmedia.admin.search"
                                    )}
                                />
                            </div>
                            <div className="col-1">
                                <Statistics catalog={selectedCatalogOptions} />
                            </div>
                        </div>
                    </Widget.Body>
                    <Widget.Body className="widget-table-overflow pb-1">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                bootstrap4
                                striped
                                hover
                                classes="mb-1"
                                noDataIndication={
                                    <I18n t="moodmedia.admin.empty" />
                                }
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                            />
                        )}
                    </Widget.Body>
                </Widget>
            )}
        </ToolkitProvider>
    );
};

export default Admin;
