import React from "react";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import ToolkitProvider, {
    CSVExport,
} from "@musicstory/react-bootstrap-table2-toolkit";
import Loader from "shared/components/Loader";
import EditMatchingsModal from "shared/components/EditMatchingsModal";
import I18n from "shared/lib/I18n";
import Filters from "./Filters";

const ArtistList = ({
    partner,
    date,
    top,
    isLoading,
    artists,
    columns,
    pagination,
    filters,
    setFilters,
    rowClasses,
    matchingsModalArtist,
    setMatchingsModalArtistID,
    onMatchingChange,
    fetchCandidates,
    locale,
}) => {
    if (
        partner === undefined ||
        date === undefined ||
        (partner != "qb" && top === undefined)
    ) {
        return null;
    }

    const { ExportCSVButton } = CSVExport;

    return (
        <>
            <EditMatchingsModal
                artistId={matchingsModalArtist?.artistId}
                artistName={matchingsModalArtist?.name}
                matched={matchingsModalArtist?.matchingId !== null}
                show={matchingsModalArtist !== null}
                onHide={() => setMatchingsModalArtistID(null)}
                locale={locale}
                onChange={onMatchingChange}
                fetchCandidates={fetchCandidates}
            />
            <ToolkitProvider
                keyField="id"
                data={artists}
                columns={columns}
                exportCSV={{
                    fileName: "partner_top.csv",
                }}
            >
                {(toolkitprops) => (
                    <PaginationProvider pagination={pagination}>
                        {({ paginationProps, paginationTableProps }) => (
                            <section className="widget">
                                <header className="d-flex mb-0">
                                    <h6 className="flex-grow-1">
                                        <I18n t="partner_tops.artists.title" />
                                    </h6>
                                    {!isLoading && (
                                        <>
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                            <ExportCSVButton
                                                {...toolkitprops.csvProps}
                                                className="ml-2"
                                            >
                                                <I18n t="utils.button.export" />
                                            </ExportCSVButton>
                                        </>
                                    )}
                                </header>
                                <Filters
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                                <div
                                    className="widget-body widget-table-overflow"
                                    style={{
                                        margin: "10px -20px 0px -20px",
                                    }}
                                >
                                    <Loader
                                        className={isLoading ? "" : "d-none"}
                                    />
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        rowClasses={rowClasses}
                                        bootstrap4
                                        striped
                                        hover
                                        bordered
                                        noDataIndication={
                                            <I18n t="partner_tops.artists.empty" />
                                        }
                                        wrapperClasses={
                                            isLoading ? "d-none" : null
                                        }
                                        classes="mb-0 table-header-fixed border-top-0"
                                        // This is a workaround for the following bug,
                                        // which occurs when there is no row to display
                                        // on the current page as a result of a search :
                                        // https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/1512
                                        onDataSizeChange={() => {
                                            if (paginationProps.page !== 1) {
                                                paginationProps.onPageChange(1);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="widget-body">
                                    <div className="row align-items-center pt-3">
                                        {!isLoading && (
                                            <>
                                                <div className="col ">
                                                    <PaginationTotalStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>
                        )}
                    </PaginationProvider>
                )}
            </ToolkitProvider>
        </>
    );
};

export default ArtistList;
