import React from "react";
import Loader from "shared/components/Loader";
import ArtistSelect from "shared/components/ArtistSelect";
import EditMatchingsTable from "shared/components/EditMatchingsTable";

const EditMatchingsForm = ({
    isLoading,
    status,
    candidates,
    onChange,
    onMatch,
    locale,
}) => {
    return isLoading ? (
        <Loader />
    ) : (
        <>
            <ArtistSelect
                onMatch={onMatch}
                locale={locale}
                className="m-4"
                enablePortal
            />
            <EditMatchingsTable
                status={status}
                candidates={candidates}
                onChangeState={onChange}
            />
        </>
    );
};

export default EditMatchingsForm;
