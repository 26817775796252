import React, { useState, useCallback, useEffect } from "react";
import Historic from "./Historic";
import { useLocation, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { getLabels, getData } from "./Historic.pure";
import I18n from "shared/lib/I18n";

const EnhancedHistoric = ({ remaining, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const [{ historic, isLoading }, setState] = useState({
        historic: [],
        isLoading: true,
    });

    const getHistoric = useCallback(() => {
        return api
            .get(`user/api/historic`, { locale })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    historic: response,
                    isLoading: false,
                }));
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        getHistoric();
    }, [getHistoric]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                usePointStyle: true,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: I18n.getTranslation(
                        location,
                        "api.monitoring.historic.remaining"
                    ),
                },
                ticks: {
                    stepSize: 1,
                },
            },
            x: {
                title: {
                    display: true,
                    text: I18n.getTranslation(
                        location,
                        "api.monitoring.historic.date"
                    ),
                },
                type: "time",
                time: {
                    displayFormats: {
                        day: "YYYY-MM-DD",
                    },
                    tooltipFormat: "YYYY-MM-DD",
                },
            },
        },
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
        },
    };

    const data = {
        labels: getLabels({
            historic,
        }),
        datasets: [
            {
                data: getData({ historic, remaining }),
                tension: 0.1,
                cubicInterpolationMode: "monotone",
            },
        ],
    };

    return (
        <Historic
            {...props}
            isLoading={isLoading}
            data={data}
            options={options}
        />
    );
};

export default EnhancedHistoric;
