import React from "react";
import { Button } from "react-bootstrap";
import I18n from "shared/lib/I18n";

const Delete = ({ disabled, onClick, location }) => {
    return (
        <Button
            variant="danger"
            disabled={disabled}
            onClick={onClick}
            title={I18n.getTranslation(
                location,
                disabled
                    ? "artist.management.disco.objects.release.basic.delete.disabledTitle"
                    : "artist.management.disco.objects.release.basic.delete.title"
            )}
            className="ml-2"
        >
            <I18n t="utils.button.delete" />
        </Button>
    );
};

export default Delete;
