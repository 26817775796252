import React from "react";
import { Tab, Nav } from "react-bootstrap";
import BasicAlbum from "./Album/Basic";
import Artist from "./Album/Artist";
import Cover from "./Album/Cover";
import Review from "./Album/Review";
import TranslationsAlbum from "./Album/Translations";
import BasicRelease from "./Release/Basic";
import MatchingRelease from "./Release/Matching";
import Tracklist from "./Release/Tracklist";
import BasicTrack from "./Track/Basic";
import TranslationsTrack from "./Track/Translations";
import MatchingTrack from "./Track/Matching";
import I18n from "shared/lib/I18n";
import "./Objects.css";

const Objects = ({ selected, setSelected, albumId, releaseId, trackId }) => {
    return (
        <Tab.Container
            id="objects-tab"
            activeKey={selected}
            onSelect={(k) => setSelected(k)}
        >
            <Nav variant="pills" justify>
                <Nav.Item>
                    <Nav.Link eventKey="album" disabled={!albumId}>
                        <I18n t="artist.management.disco.objects.album.tabTitle" />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="release" disabled={!releaseId}>
                        <I18n t="artist.management.disco.objects.release.tabTitle" />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="track" disabled={!trackId}>
                        <I18n t="artist.management.disco.objects.track.tabTitle" />
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Tab.Content className="nav-content">
                <Tab.Pane eventKey="album" className="nav-pane">
                    <BasicAlbum />
                    <TranslationsAlbum />
                    <Artist />
                    <Cover />
                    <Review />
                </Tab.Pane>
                <Tab.Pane eventKey="release" className="nav-pane">
                    <BasicRelease />
                    <MatchingRelease />
                    <Tracklist />
                </Tab.Pane>
                <Tab.Pane eventKey="track" className="nav-pane">
                    <BasicTrack />
                    <TranslationsTrack />
                    <MatchingTrack />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
};

export default Objects;
