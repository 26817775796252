import React, { useMemo } from "react";
import Artists from "./Artists";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import paginationFactory from "@musicstory/react-bootstrap-table2-paginator";
import ArtistListAction from "shared/components/ArtistListAction";
import ImageLoader from "shared/components/ImageLoader";
import placeholder from "assets/static/images/logo_placeholder.png";
import useQuery from "shared/hooks/useQuery";

const EnhancedArtists = ({ artists, limit, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const query = useQuery();

    const sliceData = useMemo(() => {
        if (limit) {
            return artists.slice(0, limit);
        }
        return artists;
    }, [artists, limit]);

    const columns = [
        {
            dataField: "cover",
            text: I18n.getTranslation(location, "search.artistsData.cover"),
            formatter: (cell) => {
                return (
                    <ImageLoader
                        src={cell}
                        fallbackSrc={placeholder}
                        fallbackClassName="cover-placeholder"
                        className="cover-artist"
                    />
                );
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "id",
            text: I18n.getTranslation(location, "search.artistsData.id"),
            formatter: (cell) => {
                return cell ? <strong>{cell}</strong> : "-";
            },
            headerStyle: { width: "8rem" },
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "search.artistsData.name"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        !limit && {
            dataField: "complement",
            text: I18n.getTranslation(
                location,
                "search.artistsData.complementary"
            ),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        !limit && {
            dataField: "peak",
            text: I18n.getTranslation(location, "search.artistsData.peak"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "type",
            text: I18n.getTranslation(location, "search.artistsData.type"),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell ? cell.langs?.[locale] : "-";
            },
            formatExtraData: {
                locale,
            },
        },
        !limit && {
            dataField: "genre",
            text: I18n.getTranslation(location, "search.artistsData.genre"),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell ? cell.langs?.[locale] : "-";
            },
            formatExtraData: {
                locale,
            },
        },
        {
            dataField: "country",
            text: I18n.getTranslation(location, "search.artistsData.country"),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell ? cell.langs?.[locale] : "-";
            },
            formatExtraData: {
                locale,
            },
        },
        !limit && {
            dataField: "role",
            text: I18n.getTranslation(location, "search.artistsData.role"),
            formatter: (cell, row, rowIndex, { locale }) => {
                return cell ? cell.langs?.[locale] : "-";
            },
            formatExtraData: {
                locale,
            },
        },
        {
            dataField: "actions",
            isDummy: true,
            text: I18n.getTranslation(location, "search.artistsData.actions"),
            formatter: (cell, row) => {
                return (
                    <ArtistListAction
                        msArtist={{ id: row.id, name: row.name }}
                    />
                );
            },
            headerAlign: "right",
            align: "right",
        },
    ].filter(Boolean);

    const pagination = paginationFactory({
        custom: true,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPage: 50,
        totalSize: sliceData.length,
        nextPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.next_page"
        ),
        prePageTitle: I18n.getTranslation(
            location,
            "utils.pagination.previous_page"
        ),
        firstPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.first_page"
        ),
        lastPageTitle: I18n.getTranslation(
            location,
            "utils.pagination.last_page"
        ),
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                <I18n t="utils.pagination.total" args={{ size }} />
            </span>
        ),
    });

    return (
        <Artists
            {...props}
            artists={sliceData}
            columns={columns}
            limit={limit}
            pagination={pagination}
            location={location}
            query={query}
        />
    );
};

export default EnhancedArtists;
