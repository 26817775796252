import React, { useCallback, useState } from "react";
import Sandbox from "./Sandbox";

const EnhancedSandbox = (props) => {
    const [popoverState, setPopoverState] = useState({
        show: false,
        row: {},
        target: null,
    });

    const showPopover = useCallback(
        (row, target) =>
            setPopoverState({
                show: true,
                row: row,
                target: target,
            }),
        []
    );
    const hidePopover = useCallback(
        () =>
            setPopoverState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    const [matchingsState, setMatchingsState] = useState({
        show: false,
        matchings: [],
    });

    const showMatchings = useCallback(
        (matchings) =>
            setMatchingsState({
                show: true,
                matchings: matchings,
            }),
        []
    );
    const hideMatchings = useCallback(
        () =>
            setMatchingsState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    const [tracklistComparisonState, setTracklistComparisonState] = useState({
        show: false,
        partnerAbbreviatedName: null,
        partnerId: null,
    });

    const showTracklistComparison = useCallback(
        (partnerAbbreviatedName, partnerId) =>
            setTracklistComparisonState({
                show: true,
                partnerAbbreviatedName: partnerAbbreviatedName,
                partnerId: partnerId,
            }),
        []
    );
    const hideTracklistComparison = useCallback(
        () =>
            setTracklistComparisonState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    const [copyProductState, setCopyProductState] = useState({
        show: false,
        product: null,
    });

    const showCopyProduct = useCallback(
        (product) =>
            setCopyProductState({
                show: true,
                product: product,
            }),
        []
    );
    const hideCopyProduct = useCallback(
        () =>
            setCopyProductState((state) => ({
                ...state,
                show: false,
            })),
        []
    );

    return (
        <>
            <Sandbox
                {...props}
                popoverState={popoverState}
                showPopover={showPopover}
                hidePopover={hidePopover}
                matchingsState={matchingsState}
                showMatchings={showMatchings}
                hideMatchings={hideMatchings}
                tracklistComparisonState={tracklistComparisonState}
                showTracklistComparison={showTracklistComparison}
                hideTracklistComparison={hideTracklistComparison}
                copyProductState={copyProductState}
                showCopyProduct={showCopyProduct}
                hideCopyProduct={hideCopyProduct}
            />
        </>
    );
};

export default EnhancedSandbox;
