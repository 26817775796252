import React, { useState, useCallback, useEffect } from "react";
import Status from "./Status";
import { useLocation, useParams } from "react-router";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import moment from "moment";

const EnhancedStatus = ({ setRemaining, ...props }) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const [state, setState] = useState({
        status: {},
    });

    const getStatus = useCallback(() => {
        return api
            .get(`user/api/status`, { locale })
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    status: {
                        remaining: response["X-Rate-Limit-Remaining"],
                        limit: response["X-Rate-Limit-Limit"],
                        retry: response["X-Rate-Limit-Retry-After"],
                    },
                }));
                setRemaining?.(response["X-Rate-Limit-Remaining"]);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, []);

    useEffect(() => {
        getStatus();
    }, [getStatus]);

    const getRetryDate = (seconds) => {
        if (!seconds) {
            return "-";
        }

        const now = moment();
        now.add(seconds, "seconds");

        return now.format("YYYY/MM/DD HH:mm:ss");
    };

    return (
        <Status
            {...props}
            {...state}
            location={location}
            getRetryDate={getRetryDate}
        />
    );
};

export default EnhancedStatus;
