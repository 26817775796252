import React from "react";
import MoodmediaAdmin from "./MoodmediaAdmin";
import { useLocation } from "react-router";

const EnhancedMoodmediaAdmin = (props) => {
    const location = useLocation();
    return <MoodmediaAdmin {...props} location={location} />;
};

export default EnhancedMoodmediaAdmin;
