import React from "react";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { Form, Button } from "react-bootstrap";
import InputText from "shared/components/Form/InputText";
import { TYPE_BAND } from "shared/constants/cms/artist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

const EditorialInformation = ({
    getEditorialInformation,
    isLoading,
    handleSubmit,
    errors,
    register,
    isSubmitting,
    isDirty,
    onSubmit,
    location,
    artistType,
    fields,
    append,
    remove,
}) => {
    return (
        <Widget
            title={`artist.management.editorialInformation.head`}
            enableCollapse
        >
            <Widget.Body className="pt-3 border-top">
                {isLoading ? (
                    <Loader />
                ) : (
                    <Form
                        onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}
                    >
                        <div className="row">
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.born`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("born")}
                                    error={
                                        errors.born && [
                                            I18n.getTranslation(
                                                location,
                                                errors.born.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            {artistType === TYPE_BAND && (
                                <Form.Group className="col-12">
                                    <Form.Label>
                                        <I18n
                                            t={`artist.management.editorialInformation.${TYPE_BAND}.debut_date`}
                                        />
                                    </Form.Label>
                                    <InputText
                                        {...register("debut_date")}
                                        error={
                                            errors.debut_date && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.debut_date.message
                                                ),
                                            ]
                                        }
                                    />
                                </Form.Group>
                            )}
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.birth_country`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("birth_country")}
                                    error={
                                        errors.birth_country && [
                                            I18n.getTranslation(
                                                location,
                                                errors.birth_country.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.birth_area`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("birth_area")}
                                    error={
                                        errors.birth_area && [
                                            I18n.getTranslation(
                                                location,
                                                errors.birth_area.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.birth_city`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("birth_city")}
                                    error={
                                        errors.birth_city && [
                                            I18n.getTranslation(
                                                location,
                                                errors.birth_city.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            {artistType === TYPE_BAND && (
                                <Form.Group className="col-12">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <Form.Label>
                                            <I18n
                                                t={`artist.management.editorialInformation.${TYPE_BAND}.activity_period`}
                                            />
                                        </Form.Label>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                append({
                                                    start_date: null,
                                                    end_date: null,
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                fixedWidth
                                            />
                                        </Button>
                                    </div>
                                    <hr className="my-2" />
                                    {fields.map((item, index) => (
                                        <div
                                            key={item.id}
                                            className="d-flex align-items-start mt-2"
                                        >
                                            <div className="mr-2">
                                                <InputText
                                                    {...register(
                                                        `activity_period.${index}.start_date`
                                                    )}
                                                    placeholder={I18n.getTranslation(
                                                        location,
                                                        `artist.management.editorialInformation.${TYPE_BAND}.activity_period_start`
                                                    )}
                                                    error={
                                                        errors
                                                            ?.activity_period?.[
                                                            index
                                                        ]?.start_date && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    ?.activity_period?.[
                                                                    index
                                                                ]?.start_date
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            </div>
                                            <div className="mr-2">
                                                <InputText
                                                    {...register(
                                                        `activity_period.${index}.end_date`
                                                    )}
                                                    placeholder={I18n.getTranslation(
                                                        location,
                                                        `artist.management.editorialInformation.${TYPE_BAND}.activity_period_end`
                                                    )}
                                                    error={
                                                        errors
                                                            ?.activity_period?.[
                                                            index
                                                        ]?.end_date && [
                                                            I18n.getTranslation(
                                                                location,
                                                                errors
                                                                    ?.activity_period?.[
                                                                    index
                                                                ]?.end_date
                                                                    .message
                                                            ),
                                                        ]
                                                    }
                                                />
                                            </div>
                                            <Button
                                                variant="danger"
                                                onClick={() => remove(index)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    fixedWidth
                                                />
                                            </Button>
                                        </div>
                                    ))}
                                </Form.Group>
                            )}
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.died`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("died")}
                                    error={
                                        errors.died && [
                                            I18n.getTranslation(
                                                location,
                                                errors.died.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.death_country`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("death_country")}
                                    error={
                                        errors.death_country && [
                                            I18n.getTranslation(
                                                location,
                                                errors.death_country.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.death_area`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("death_area")}
                                    error={
                                        errors.death_area && [
                                            I18n.getTranslation(
                                                location,
                                                errors.death_area.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-12">
                                <Form.Label>
                                    <I18n
                                        t={`artist.management.editorialInformation.${artistType}.death_city`}
                                    />
                                </Form.Label>
                                <InputText
                                    {...register("death_city")}
                                    error={
                                        errors.death_city && [
                                            I18n.getTranslation(
                                                location,
                                                errors.death_city.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <Button
                                variant="secondary ml-2"
                                disabled={isSubmitting || isLoading}
                                onClick={
                                    !isSubmitting && !isLoading
                                        ? getEditorialInformation
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    "artist.management.editorialInformation.reset.title"
                                )}
                            >
                                <I18n t="utils.button.reset" />
                            </Button>
                            <Button
                                variant="success"
                                disabled={isSubmitting || isLoading || !isDirty}
                                onClick={
                                    !isSubmitting && !isLoading && isDirty
                                        ? handleSubmit(onSubmit)
                                        : null
                                }
                                title={I18n.getTranslation(
                                    location,
                                    `artist.management.editorialInformation.update.title`
                                )}
                            >
                                {isSubmitting ? (
                                    <Loader size="1x" />
                                ) : (
                                    <I18n t={`utils.button.edit`} />
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Widget.Body>
        </Widget>
    );
};

export default EditorialInformation;
