import React from "react";
import { Form, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import GenreSelect from "shared/components/GenreSelect";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const CreateOrUpdate = ({
    trackId,
    initForm,
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    hitOptions,
    discoveryOptions,
    languageOptions,
    dialectOptions,
    isDirty,
    onSubmit,
    location,
    locale,
}) => {
    if (isLoading) {
        return <Loader />;
    }

    return (
        <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
            <div className="row">
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.no_cd" />
                    </Form.Label>
                    <InputText
                        {...register("no_cd")}
                        error={
                            errors.no_cd && [
                                I18n.getTranslation(
                                    location,
                                    errors.no_cd.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.no_piste" />
                    </Form.Label>
                    <InputText
                        {...register("no_piste")}
                        error={
                            errors.no_piste && [
                                I18n.getTranslation(
                                    location,
                                    errors.no_piste.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-12">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.title" />
                    </Form.Label>
                    <InputText
                        {...register("title")}
                        error={
                            errors.title && [
                                I18n.getTranslation(
                                    location,
                                    errors.title.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
            </div>
            <div className="row">
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.subtitle" />
                    </Form.Label>
                    <InputText
                        {...register("subtitle")}
                        error={
                            errors.subtitle && [
                                I18n.getTranslation(
                                    location,
                                    errors.subtitle.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.duration" />
                    </Form.Label>
                    <InputText
                        {...register("duration")}
                        placeholder={I18n.getTranslation(
                            location,
                            "utils.form.duration_placeholder"
                        )}
                        error={
                            errors.duration && [
                                I18n.getTranslation(
                                    location,
                                    errors.duration.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-4">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.isrc" />
                    </Form.Label>
                    <InputText
                        {...register("isrc")}
                        error={
                            errors.isrc && [
                                I18n.getTranslation(
                                    location,
                                    errors.isrc.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-4">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.hit" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="hit"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null
                                        ? {
                                              value: value,
                                              label: value,
                                          }
                                        : null
                                }
                                options={hitOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                enablePortal={trackId}
                            />
                        )}
                    />
                </Form.Group>

                <Form.Group className="col-4">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.discovery" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="discovery"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null
                                        ? {
                                              value: value,
                                              label: value,
                                          }
                                        : null
                                }
                                options={discoveryOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                enablePortal={trackId}
                            />
                        )}
                    />
                </Form.Group>
            </div>
            <div className="row">
                <Form.Group className="col">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.language" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="id_language"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null
                                        ? languageOptions.find(
                                              (r) => r.value === value
                                          )
                                        : null
                                }
                                options={languageOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                enablePortal={trackId}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.dialect" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="id_dialect"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null
                                        ? dialectOptions.find(
                                              (r) => r.value === value
                                          )
                                        : null
                                }
                                options={dialectOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                enablePortal={trackId}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.track.basic.genre" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="genre"
                        render={({ field: { onChange, value, ref } }) => (
                            <GenreSelect
                                innerRef={ref}
                                value={
                                    value !== null && value !== undefined
                                        ? {
                                              value: value.id,
                                              label: value.name,
                                          }
                                        : null
                                }
                                onMatch={(id, label) => {
                                    onChange({
                                        id: id,
                                        name: label,
                                        protected: true,
                                    });
                                }}
                                onUnmatch={() => {
                                    onChange(null);
                                }}
                                locale={locale}
                                enablePortal={trackId}
                            />
                        )}
                    />
                </Form.Group>
            </div>
            <div className="d-flex flex-row-reverse pb-3">
                <Button
                    variant="secondary ml-2"
                    disabled={isSubmitting || isLoading}
                    onClick={!isSubmitting && !isLoading ? initForm : null}
                    title={I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.track.basic.reset.title"
                    )}
                >
                    <I18n t="utils.button.reset" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting || isLoading || !isDirty}
                    onClick={
                        !isSubmitting && !isLoading && isDirty
                            ? handleSubmit(onSubmit)
                            : null
                    }
                    title={I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.track.basic.${
                            trackId ? "update" : "create"
                        }.title`
                    )}
                >
                    <I18n t={`utils.button.${trackId ? "edit" : "add"}`} />
                </Button>
            </div>
        </Form>
    );
};

export default CreateOrUpdate;
