import DeezerTrack from "./Track/DeezerTrack";
import SnepTrack from "./Track/SnepTrack";

export const PARTNERS = [
    { id: "deezer", name: "Deezer" },
    { id: "snep", name: "Snep" },
];

export const PARTNERS_TRACK_COMPONENT = {
    deezer: DeezerTrack,
    snep: SnepTrack,
};
