import React from "react";
import { Button, Modal } from "react-bootstrap";
import I18n from "shared/lib/I18n";
import TransferForm from "pages/ArtistManagement/Discography/Forms/Release/TransferForm";

const Transfer = ({
    artistId,
    albumId,
    releaseId,
    show,
    handleShow,
    handleClose,
    disabled,
    onSuccess,
    location,
}) => {
    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                title={I18n.getTranslation(
                    location,
                    "artist.management.disco.objects.release.basic.transfer.title"
                )}
                disabled={disabled}
            >
                <I18n t="utils.button.transfer" />
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n t="artist.management.disco.objects.release.basic.transfer.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransferForm
                        artistId={artistId}
                        albumId={albumId}
                        releaseId={releaseId}
                        onSuccess={onSuccess}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Transfer;
