import React from "react";
import I18n from "shared/lib/I18n";
import Task from "./Task";
import Loader from "shared/components/Loader";
import { NavLink } from "shared/lib/I18n";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const PlanningApple = ({
    planning,
    tasks,
    isLoading,
    handleStatusChanged,
    hasRight,
}) => {
    let tasksList = (
        <tr>
            <td colSpan="42" style={{ textAlign: "center" }}>
                <I18n t="plannings.production.tasks.empty" />
            </td>
        </tr>
    );

    if (tasks.length !== 0) {
        tasksList = tasks.map((task) => (
            <Task
                key={task.id}
                task={task}
                handleStatusChanged={handleStatusChanged}
            />
        ));
    }

    const title = [
        planning.title,
        planning.start_date,
        planning.end_date,
        planning.partner_name,
    ];

    return (
        <section className="widget">
            <header>
                {hasRight("plannings.manage") ? (
                    <NavLink
                        to={`/plannings/${planning.id}/${PLANNING_PARTNER.ALL}`}
                    >
                        <h4 className="d-inline">
                            {title.filter(Boolean).join(" - ")}
                        </h4>
                    </NavLink>
                ) : (
                    <h4>{title.filter(Boolean).join(" - ")}</h4>
                )}
            </header>
            <div className="widget-body widget-table-overflow">
                {isLoading ? (
                    <Loader />
                ) : (
                    <table
                        className="table table-striped table-header-fixed"
                        style={{ marginBottom: "0px" }}
                    >
                        <thead>
                            <tr>
                                <th>
                                    <I18n t="plannings.production.tasks.artist" />
                                </th>
                                <th>
                                    <I18n t="plannings.production.tasks.album" />
                                </th>
                                <th>
                                    <I18n t="plannings.production.tasks.track" />
                                </th>
                                <th>
                                    <I18n t="plannings.production.tasks.category" />
                                </th>
                                <th>
                                    <I18n t="plannings.production.tasks.tasks" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{tasksList}</tbody>
                    </table>
                )}
            </div>
        </section>
    );
};

export default PlanningApple;
