import React from "react";
import { Link } from "react-router-dom";
import Artists from "./Artists";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCompactDisc } from "@fortawesome/free-solid-svg-icons";
import useUser from "shared/hooks/useUser";

const EnhancedArtists = (props) => {
    const location = useLocation();
    const { locale } = useParams();
    const { user } = useUser();

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "moodmedia.search.id"),
        },
        {
            dataField: "name",
            text: I18n.getTranslation(location, "moodmedia.search.name"),
        },
        {
            dataField: "mbid",
            text: I18n.getTranslation(location, "moodmedia.search.mbid"),
        },
        {
            dataField: "match",
            text: I18n.getTranslation(location, "moodmedia.search.match"),
        },
        {
            dataField: "popularity",
            text: I18n.getTranslation(location, "moodmedia.search.popularity"),
        },
        {
            dataField: "dummyLink",
            isDummy: true,
            text: I18n.getTranslation(location, "moodmedia.search.link"),
            headerStyle: {
                width: "10rem",
            },
            align: "center",
            formatter: (cell, { id }) => (
                <div className="ml-3 mb-3">
                    <Link
                        to={{
                            pathname: `/${locale}/moodmedia/recommendation`,
                            search: `?msArtistIds=${id}`,
                        }}
                        target="_blank"
                        className="btn btn-primary btn-sm"
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
                    </Link>
                    {user.hasRight("api.recommendation") && (
                        <Link className="mr-1"
                            to={{
                                pathname: `/${locale}/tools/recommendation`,
                                search: `?msArtistIds=${id}`,
                            }}
                            target="_blank"
                            className="btn btn-success btn-sm"
                        >
                            <FontAwesomeIcon icon={faCompactDisc} size="lg" />
                        </Link>
                    )}
                </div>
            ),
            searchable: false,
        },
    ];

    return <Artists columns={columns} {...props} />;
};

export default EnhancedArtists;
