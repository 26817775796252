import React from "react";
import InputSelect from "shared/components/Form/InputSelect";
import InputDate from "shared/components/Form/InputDate";
import MultiArtistSelect from "shared/components/MultiArtistSelect";
import I18n from "shared/lib/I18n";
import { Accordion, Card, Modal, Button, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Loader from "shared/components/Loader";
import ImageLoader from "shared/components/ImageLoader";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const PictureTagsModal = ({
    picture,
    show,
    handleHide,
    tags,
    errors,
    isDirty,
    control,
    isSubmitting,
    onSubmit,
    isLoading,
    locale,
    location,
    isVersion,
    isEdit,
}) => {
    return (
        <Modal
            show={show}
            onHide={handleHide}
            animation={false}
            dialogClassName="modal-full"
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`common.pictureTagsModal.${
                            isVersion ? "titleVersion" : "title"
                        }`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ImageLoader
                                    src={picture?.url_version}
                                    className="display-block mx-auto mw-100 sticky-top"
                                    style={{ maxHeight: "calc(100vh - 15rem)" }}
                                />
                            </div>
                            <div className="col">
                                <Accordion
                                    defaultActiveKey="tags"
                                    className="border"
                                >
                                    <Card className="border-bottom">
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            className="text-link cursor-pointer"
                                            eventKey="metas"
                                        >
                                            <h4 className="mb-0 font-weight-bold">
                                                <I18n t="common.pictureTagsModal.metas" />
                                            </h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="metas">
                                            <Card.Body>
                                                <table className="table table-bordered table-sm table-hover table-striped">
                                                    <tbody>
                                                        {picture?.metas.map(
                                                            (
                                                                { key, value },
                                                                index
                                                            ) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {key}
                                                                    </td>
                                                                    <td>
                                                                        {value}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            className="text-link cursor-pointer"
                                            eventKey="tags"
                                        >
                                            <h4 className="mb-0 font-weight-bold">
                                                <I18n t="common.pictureTagsModal.tags" />
                                            </h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="tags">
                                            <Card.Body className="pb-2">
                                                {tags.map(
                                                    ({
                                                        group: { id, name },
                                                        tags: options,
                                                    }) => (
                                                        <div
                                                            key={id}
                                                            className="row"
                                                        >
                                                            <div className="col-6">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        {name}
                                                                        {[
                                                                            2,
                                                                            7,
                                                                            4,
                                                                            5,
                                                                            6,
                                                                        ].includes(
                                                                            id
                                                                        ) && (
                                                                            <Tippy
                                                                                content={I18n.getTranslation(
                                                                                    location,
                                                                                    `common.pictureTagsModal.description.${id}`
                                                                                )}
                                                                                trigger="mouseenter"
                                                                            >
                                                                                <span className="ml-1">
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faInfoCircle
                                                                                        }
                                                                                        fixedWidth
                                                                                    />
                                                                                </span>
                                                                            </Tippy>
                                                                        )}
                                                                    </Form.Label>
                                                                    <Controller
                                                                        control={
                                                                            control
                                                                        }
                                                                        name={`${id}`}
                                                                        render={({
                                                                            field: {
                                                                                onChange,
                                                                                value,
                                                                                ref,
                                                                            },
                                                                        }) => (
                                                                            <InputSelect
                                                                                innerRef={
                                                                                    ref
                                                                                }
                                                                                value={
                                                                                    value !==
                                                                                    null
                                                                                        ? options.find(
                                                                                              ({
                                                                                                  value: v,
                                                                                              }) =>
                                                                                                  v ===
                                                                                                  value
                                                                                          )
                                                                                        : null
                                                                                }
                                                                                options={options.filter(
                                                                                    ({
                                                                                        enabled,
                                                                                    }) =>
                                                                                        enabled
                                                                                )}
                                                                                onChange={(
                                                                                    opt
                                                                                ) => {
                                                                                    onChange(
                                                                                        opt
                                                                                            ? opt.value
                                                                                            : null
                                                                                    );
                                                                                }}
                                                                                isClearable={
                                                                                    false
                                                                                }
                                                                                error={
                                                                                    errors[
                                                                                        id
                                                                                    ] && [
                                                                                        I18n.getTranslation(
                                                                                            location,
                                                                                            errors[
                                                                                                id
                                                                                            ]
                                                                                                .message
                                                                                        ),
                                                                                    ]
                                                                                }
                                                                                isDisabled={
                                                                                    !isVersion &&
                                                                                    isEdit &&
                                                                                    value !==
                                                                                        null &&
                                                                                    ![
                                                                                        1,
                                                                                        5,
                                                                                        6,
                                                                                    ].includes(
                                                                                        id
                                                                                    )
                                                                                }
                                                                                enablePortal
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            {id === 4 && (
                                                                <div className="col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            <I18n t="common.pictureTagsModal.shotDate" />
                                                                        </Form.Label>
                                                                        <Controller
                                                                            control={
                                                                                control
                                                                            }
                                                                            name="shot_date"
                                                                            render={({
                                                                                field: {
                                                                                    onChange,
                                                                                    value,
                                                                                },
                                                                            }) => (
                                                                                <InputDate
                                                                                    selected={
                                                                                        value
                                                                                            ? new Date(
                                                                                                  value
                                                                                              )
                                                                                            : null
                                                                                    }
                                                                                    onChange={(
                                                                                        date
                                                                                    ) => {
                                                                                        onChange(
                                                                                            date
                                                                                                ? moment(
                                                                                                      date
                                                                                                  ).format(
                                                                                                      "YYYY-MM-DD"
                                                                                                  )
                                                                                                : null
                                                                                        );
                                                                                    }}
                                                                                    error={
                                                                                        errors.shot_date && [
                                                                                            I18n.getTranslation(
                                                                                                location,
                                                                                                errors
                                                                                                    .shot_date
                                                                                                    .message
                                                                                            ),
                                                                                        ]
                                                                                    }
                                                                                    disabled={
                                                                                        !isVersion &&
                                                                                        isEdit
                                                                                    }
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    isClearable={
                                                                                        isVersion ||
                                                                                        !isEdit
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            )}
                                                            {id === 5 && (
                                                                <div className="col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            <I18n t="common.pictureTagsModal.msArtists" />
                                                                        </Form.Label>
                                                                        <Controller
                                                                            control={
                                                                                control
                                                                            }
                                                                            name="ms_artists"
                                                                            render={({
                                                                                field: {
                                                                                    onChange,
                                                                                    value,
                                                                                    name,
                                                                                    ref,
                                                                                },
                                                                            }) => (
                                                                                <MultiArtistSelect
                                                                                    innerRef={
                                                                                        ref
                                                                                    }
                                                                                    name={
                                                                                        name
                                                                                    }
                                                                                    selected={
                                                                                        value
                                                                                    }
                                                                                    locale={
                                                                                        locale
                                                                                    }
                                                                                    onMatch={(
                                                                                        id,
                                                                                        name
                                                                                    ) =>
                                                                                        onChange(
                                                                                            [
                                                                                                ...value,
                                                                                                {
                                                                                                    id,
                                                                                                    name,
                                                                                                },
                                                                                            ]
                                                                                        )
                                                                                    }
                                                                                    onUnmatch={(
                                                                                        id
                                                                                    ) =>
                                                                                        onChange(
                                                                                            value.filter(
                                                                                                (
                                                                                                    artist
                                                                                                ) =>
                                                                                                    artist.id !==
                                                                                                    id
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onClear={() =>
                                                                                        onChange(
                                                                                            []
                                                                                        )
                                                                                    }
                                                                                    placeholder={I18n.getTranslation(
                                                                                        location,
                                                                                        "common.pictureTagsModal.msArtistsPlaceholder"
                                                                                    )}
                                                                                    isDisabled={
                                                                                        !isVersion &&
                                                                                        isEdit
                                                                                    }
                                                                                    enablePortal
                                                                                    menuPlacement="auto"
                                                                                    menuPosition="fixed"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting || isLoading || !isDirty}
                    onClick={
                        !isSubmitting && !isLoading && isDirty ? onSubmit : null
                    }
                >
                    {isSubmitting ? (
                        <Loader size="1x" />
                    ) : (
                        <I18n t={`utils.button.save`} />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PictureTagsModal;
