import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import swal from "@sweetalert/with-react";
import {
    ALBUM_ADD,
    RELEASE_ADD,
    SANDBOX_ALBUM_STATUS_UPD,
    RELEASE_MATCHING_UP_TO_DATE,
    ALBUM_COVER_UP_TO_DATE,
    DiscographyContext,
} from "pages/ArtistManagement/Discography";
import { STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY } from "pages/ArtistManagement/Discography/Sandbox/Sandbox.constants";
import CopyProductModal from "./CopyProductModal";

const EnhancedCopyProductModal = ({ product, onClose, ...props }) => {
    const { api } = useAPI();
    const { dispatchDiscography } = useContext(DiscographyContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedTab, artistId, albumId, locale } = useParams();
    const [partnerTracks, setPartnerTracks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmittingAlbum, setIsSubmittingAlbum] = useState(false);
    const [isSubmittingRelease, setIsSubmittingRelease] = useState(false);

    const isProductSelected = product !== null;

    const getPartnerTracks = useCallback(() => {
        if (isProductSelected) {
            setIsLoading(true);
            api.get(
                `disco/tracklist/${product.partnerAbbreviatedName.toLowerCase()}/${
                    product.id
                }`
            )
                .then((response) => {
                    setPartnerTracks(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setPartnerTracks([]);
                    setIsLoading(false);
                });
        }
    }, [isProductSelected, product?.partnerAbbreviatedName, product?.id]);

    useEffect(getPartnerTracks, [getPartnerTracks]);

    const onCopyAsAlbum = () => {
        let withReleaseControl = 1;
        let recordingControlOption = 0;

        setIsSubmittingAlbum(true);

        const sendRequest = () => {
            api.post(
                `artist/${artistId}/album/from_partner`,
                { locale, withReleaseControl, recordingControlOption },
                product
            )
                .then(onRequestSuccess)
                .catch(onRequestError);
        };

        const onRequestSuccess = (response) => {
            toast.success(
                I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.copyProduct.copyAsAlbum.created"
                )
            );
            onClose();
            dispatchDiscography({
                type: ALBUM_ADD,
                data: response.album,
            });
            dispatchDiscography({
                type: SANDBOX_ALBUM_STATUS_UPD,
                data: {
                    ...product,
                    status: STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY,
                },
            });
            navigate(
                `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${response.album.id}/release/${response.release.id}`
            );
            setIsSubmittingAlbum(false);
        };

        const onDuplicateBarcode = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.copyProduct.copyAsAlbum.warningBarcode.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.sandbox.copyProduct.copyAsAlbum.warningBarcode.text",
                            error.message
                        )}
                        <br />
                        <a
                            href={`/${locale}/artist/${error.body.id_artist}/edit/discography/album/${error.body.id_album}/release/${error.body.id_release}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {error.body.artist_name} - {error.body.album_title}
                        </a>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    setIsSubmittingAlbum(false);
                    return;
                }

                withReleaseControl = 0;
                sendRequest();
            });
        };

        const onDuplicateIsrc = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.copyProduct.copyAsAlbum.warningIsrc.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.sandbox.copyProduct.copyAsAlbum.warningIsrc.text"
                        )}
                        <ul className="list-group border-bottom">
                            {error.body.recordings.map((row) => (
                                <li
                                    key={row.id_recording}
                                    className="list-group-item"
                                >
                                    <a
                                        href={`/${locale}/artist/${row.id_artist}/edit/discography/album/${row.id_album}/release/${row.id_release}/track/${row.id_track}/recording/${row.id_recording}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.isrc} - {row.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    create: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.create"
                        ),
                        value: 1,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                    merge: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.merge"
                        ),
                        value: 2,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    setIsSubmittingAlbum(false);
                    return;
                }

                recordingControlOption = isConfirm;
                sendRequest();
            });
        };

        const onRequestError = (error) => {
            console.error(error);
            const code = error?.body?.code;

            if (code === "duplicate_barcode") {
                onDuplicateBarcode(error);
            } else if (code === "duplicate_isrc") {
                onDuplicateIsrc(error);
            } else {
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.copyProduct.copyAsAlbum.error",
                        error.message
                    )
                );
                setIsSubmittingAlbum(false);
            }
        };

        sendRequest();
    };

    const onCopyAsRelease = () => {
        let withReleaseControl = 1;
        let recordingControlOption = 0;

        setIsSubmittingRelease(true);

        const sendRequest = () => {
            api.post(
                `artist/${artistId}/album/${albumId}/release/from_partner`,
                { locale, withReleaseControl, recordingControlOption },
                product
            )
                .then(onRequestSuccess)
                .catch(onRequestError);
        };

        const onRequestSuccess = (response) => {
            if (response?.code == "matched_by_barcode") {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.copyProduct.copyAsRelease.matched"
                    )
                );
                onClose();
                dispatchDiscography({
                    type: ALBUM_COVER_UP_TO_DATE,
                    data: false,
                });
                dispatchDiscography({
                    type: RELEASE_MATCHING_UP_TO_DATE,
                    data: false,
                });
                dispatchDiscography({
                    type: SANDBOX_ALBUM_STATUS_UPD,
                    data: {
                        ...product,
                        status: STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY,
                    },
                });
                navigate(
                    `/${locale}/artist/${response.id_artist}/edit/${selectedTab}/album/${response.id_album}/release/${response.id_release}`
                );
            } else {
                toast.success(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.copyProduct.copyAsRelease.created"
                    )
                );
                onClose();
                dispatchDiscography({
                    type: RELEASE_ADD,
                    data: response,
                });
                dispatchDiscography({
                    type: SANDBOX_ALBUM_STATUS_UPD,
                    data: {
                        ...product,
                        status: STATUS_MATCHED_IN_CURRENT_DISCOGRAPHY,
                    },
                });
                navigate(
                    `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${response.id}`
                );
            }
            setIsSubmittingRelease(false);
        };

        const onDuplicateBarcode = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.copyProduct.copyAsRelease.warningBarcode.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.sandbox.copyProduct.copyAsRelease.warningBarcode.text",
                            error.message
                        )}
                        <br />
                        <a
                            href={`/${locale}/artist/${error.body.id_artist}/edit/discography/album/${error.body.id_album}/release/${error.body.id_release}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {error.body.artist_name} - {error.body.album_title}
                        </a>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    confirm: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.confirm"
                        ),
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    setIsSubmittingRelease(false);
                    return;
                }

                withReleaseControl = 0;
                sendRequest();
            });
        };

        const onDuplicateIsrc = (error) => {
            swal({
                title: I18n.getTranslation(
                    location,
                    "artist.management.disco.sandbox.copyProduct.copyAsRelease.warningIsrc.title"
                ),
                content: (
                    <div>
                        {I18n.getTranslation(
                            location,
                            "artist.management.disco.sandbox.copyProduct.copyAsRelease.warningIsrc.text"
                        )}
                        <ul className="list-group border-bottom">
                            {error.body.recordings.map((row) => (
                                <li
                                    key={row.id_recording}
                                    className="list-group-item"
                                >
                                    <a
                                        href={`/${locale}/artist/${row.id_artist}/edit/discography/album/${row.id_album}/release/${row.id_release}/track/${row.id_track}/recording/${row.id_recording}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.isrc} - {row.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ),
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: I18n.getTranslation(
                            location,
                            "utils.sweetalert.cancel"
                        ),
                        visible: true,
                        closeModal: true,
                    },
                    create: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.create"
                        ),
                        value: 1,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                    merge: {
                        text: I18n.getTranslation(
                            location,
                            "utils.button.merge"
                        ),
                        value: 2,
                        className: "swal-button--validate",
                        closeModal: true,
                    },
                },
            }).then((isConfirm) => {
                if (!isConfirm) {
                    setIsSubmittingRelease(false);
                    return;
                }

                recordingControlOption = isConfirm;
                sendRequest();
            });
        };

        const onRequestError = (error) => {
            console.error(error);
            const code = error?.body?.code;

            if (code === "duplicate_barcode") {
                onDuplicateBarcode(error);
            } else if (code === "duplicate_isrc") {
                onDuplicateIsrc(error);
            } else {
                toast.error(
                    I18n.getTranslation(
                        location,
                        "artist.management.disco.sandbox.copyProduct.copyAsRelease.error",
                        error.message
                    )
                );
                setIsSubmittingRelease(false);
            }
        };

        sendRequest();
    };

    return (
        <CopyProductModal
            isLoading={isLoading}
            isSubmittingAlbum={isSubmittingAlbum}
            isSubmittingRelease={isSubmittingRelease}
            partnerTracks={partnerTracks}
            albumId={albumId}
            onClose={onClose}
            onCopyAsAlbum={onCopyAsAlbum}
            onCopyAsRelease={onCopyAsRelease}
            {...props}
        />
    );
};

export default EnhancedCopyProductModal;
