import React, { useState, useEffect, useCallback } from "react";
import PlanningApple from "./PlanningApple";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import swal from "sweetalert";
import I18n from "shared/lib/I18n";
import useAPI from "shared/hooks/useApi";
import useUser from "shared/hooks/useUser";

const EnhancedPlanningApple = ({ planning, ...props }) => {
    const { api } = useAPI();
    const { user } = useUser();
    const [state, setState] = useState({
        tasks: [],
        isLoading: true,
    });
    const { locale } = useParams();
    const location = useLocation();

    const getTasks = useCallback(() => {
        let cancelled = false;

        api.get(`plannings/${planning.id}/tasks`, {
            locale,
            mode: "production",
            partnerId: planning.partner_id,
        })
            .then((response) => {
                if (!cancelled) {
                    setState({
                        tasks: response,
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, planning.id]);

    useEffect(getTasks, [getTasks]);

    const handleStatusChanged = (subtask, newStatus) => {
        swal({
            title: I18n.getTranslation(
                location,
                `plannings.production.tasks.subtasks.confirm_change_status.${newStatus}.title`
            ),
            text: I18n.getTranslation(
                location,
                `plannings.production.tasks.subtasks.confirm_change_status.${newStatus}.text`
            ),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.cancel"
                    ),
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: I18n.getTranslation(
                        location,
                        "utils.sweetalert.confirm"
                    ),
                    visible: true,
                    closeModal: true,
                },
            },
        }).then((isConfirm) => {
            if (isConfirm) {
                if (["ERROR"].includes(newStatus)) {
                    var textarea = document.createElement("textarea");
                    textarea.rows = 6;
                    textarea.className = "swal-content__textarea";

                    textarea.onkeyup = function () {
                        swal.setActionValue({
                            confirm: this.value,
                        });
                    };
                    swal({
                        title: I18n.getTranslation(
                            location,
                            `plannings.production.tasks.subtasks.confirm_change_status.${newStatus}.comment.title`
                        ),
                        dangerMode: true,
                        content: textarea,
                        buttons: {
                            cancel: {
                                text: I18n.getTranslation(
                                    location,
                                    "utils.button.cancel"
                                ),
                                visible: true,
                                closeModal: true,
                            },
                            confirm: {
                                text: I18n.getTranslation(
                                    location,
                                    "utils.button.validate"
                                ),
                            },
                        },
                    }).then((comment) => {
                        const newComment =
                            comment && comment !== true && comment !== ""
                                ? comment
                                : null;

                        api.put(
                            `plannings/tasks/subtasks/${subtask.id}/status`,
                            {
                                locale,
                            },
                            {
                                new_status: newStatus,
                                comment: newComment || subtask.comment,
                            }
                        )
                            .then((response) => {
                                getTasks();
                                toast.success(response.message);
                            })
                            .catch((error) => {
                                console.error(error);
                                toast.error(error.message);
                            });
                        swal.close();
                    });
                } else {
                    api.put(
                        `plannings/tasks/subtasks/${subtask.id}/status`,
                        {
                            locale,
                        },
                        {
                            new_status: newStatus,
                            comment: subtask.comment,
                        }
                    )
                        .then((response) => {
                            getTasks();
                            toast.success(response.message);
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error(error.message);
                        });
                }
            }
        });
    };

    return (
        <PlanningApple
            {...props}
            {...state}
            planning={planning}
            handleStatusChanged={handleStatusChanged}
            hasRight={user.hasRight}
        />
    );
};

export default EnhancedPlanningApple;
