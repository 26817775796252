import React from "react";
import { Link } from "react-router-dom";
import Tracks from "./Tracks";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCompactDisc } from "@fortawesome/free-solid-svg-icons";
import { ListGroup } from "react-bootstrap";
import useUser from "shared/hooks/useUser";

const EnhancedTracks = (props) => {
    const location = useLocation();
    const { locale } = useParams();
    const { user } = useUser();

    const columns = [
        {
            dataField: "ms_track_id",
            text: I18n.getTranslation(location, "moodmedia.search.id"),
            headerStyle: {
                width: "10rem",
            },
        },
        {
            dataField: "original_release_date",
            text: I18n.getTranslation(
                location,
                "moodmedia.search.original_release_date"
            ),
            headerStyle: {
                width: "10rem",
            },
        },
        {
            dataField: "ms_album_title",
            text: I18n.getTranslation(
                location,
                "moodmedia.search.ms_album_title"
            ),
            headerStyle: {
                width: "10rem",
            },
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "moodmedia.search.title"),
        },
        {
            dataField: "subtitle",
            text: I18n.getTranslation(location, "moodmedia.search.subtitle"),
            formatter: (cell) => {
                return cell ?? "-";
            },
        },
        {
            dataField: "artists",
            text: I18n.getTranslation(location, "moodmedia.search.artists"),
            formatter: (cell) => {
                if (cell.length > 0) {
                    return (
                        <ListGroup variant="flush">
                            {cell.map(({ id, name }) => {
                                const values = [id, name];
                                return (
                                    <ListGroup.Item
                                        key={id}
                                        className="bg-transparent p-1"
                                    >
                                        {values.filter(Boolean).join(" - ")}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    );
                }
                return "-";
            },
        },
        {
            dataField: "match",
            text: I18n.getTranslation(location, "moodmedia.search.match"),
            headerStyle: {
                width: "8rem",
            },
        },
        {
            dataField: "popularity",
            text: I18n.getTranslation(location, "moodmedia.search.popularity"),
            headerStyle: {
                width: "10rem",
            },
        },
        {
            dataField: "dummyLink",
            isDummy: true,
            text: I18n.getTranslation(location, "moodmedia.search.link"),
            headerStyle: {
                width: "10rem",
            },
            align: "center",
            formatter: (cell, { ms_track_id }) => (
                <div className="ml-3 mb-3">
                    <Link className="mr-1"
                        to={{
                            pathname: `/${locale}/moodmedia/recommendation`,
                            search: `?msTrackIds=${ms_track_id}`,
                        }}
                        target="_blank"
                        className="btn btn-primary btn-sm"
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
                    </Link>
                    {user.hasRight("api.recommendation") && (
                        <Link className="mr-1"
                            to={{
                                pathname: `/${locale}/tools/recommendation`,
                                search: `?msTrackIds=${ms_track_id}`,
                            }}
                            target="_blank"
                            className="btn btn-success btn-sm"
                        >
                            <FontAwesomeIcon icon={faCompactDisc} size="lg" />
                        </Link>
                    )}
                </div>
            ),
            searchable: false,
        },
    ];

    return <Tracks columns={columns} {...props} />;
};

export default EnhancedTracks;
