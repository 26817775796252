import React, { useState, useEffect, useCallback, useContext } from "react";
import Releases from "./Releases";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import useIntParams from "shared/hooks/useIntParams";
import useAPI from "shared/hooks/useApi";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { displayAsString } from "shared/functions/Date";
import { AlbumViewContext, RELEASE_SELECTION_SET } from "pages/AlbumView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const defaultState = {
    releases: [],
    isLoading: true,
};

const defaultModalState = {
    show: false,
    links: [],
};

const EnhancedReleases = ({ setNoData, ...props }) => {
    const { api } = useAPI();
    const location = useLocation();
    const { albumId } = useIntParams();
    const { locale } = useParams();
    const [state, setState] = useState(defaultState);
    const [modalState, setModalState] = useState(defaultModalState);
    const { dispatchAlbum, profileSelection, profileHighlights } =
        useContext(AlbumViewContext);

    const getReleases = useCallback(() => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return api
            .get(`album/${albumId}/releases`)
            .then((response) => {
                setState((prev) => ({
                    ...prev,
                    releases: response,
                }));
                if (response.length > 0) {
                    const responseWithTrack = response.filter(
                        (e) => e.nb_track
                    );
                    dispatchAlbum({
                        type: RELEASE_SELECTION_SET,
                        data:
                            responseWithTrack.length !== 0
                                ? responseWithTrack[0].id
                                : response[0].id,
                    });
                } else {
                    setNoData("releases");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [albumId]);

    useEffect(() => {
        const init = async () => {
            await Promise.all([getReleases()]);
            setState((prev) => ({ ...prev, isLoading: false }));
        };
        init();
    }, [getReleases]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.id"
            ),
            headerTitle: () =>
                I18n.getTranslation(location, "album.view.profileReleases.id"),
            sort: true,
        },
        {
            dataField: "barcode",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.ean"
            ),
            formatter: (cell) => cell ?? "-",
            headerTitle: () =>
                I18n.getTranslation(location, "album.view.profileReleases.ean"),
            sort: true,
        },
        {
            dataField: "reference",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.reference"
            ),
            formatter: (cell) => cell ?? "-",
            title: true,
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.reference"
                ),
            classes: "custom-column",
            headerClasses: "custom-column",
        },
        {
            dataField: "nb_cd",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.nb_cd"
            ),
            formatter: (cell) => cell ?? "-",
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.nb_cd"
                ),
        },
        {
            dataField: "nb_track",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.nb_track"
            ),
            formatter: (cell) => cell ?? "-",
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.nb_track"
                ),
        },
        {
            dataField: "release_date",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.release_date"
            ),
            formatter: (cell, row, rowIndex, { locale }) => {
                if (cell) {
                    return displayAsString(cell, locale);
                } else {
                    return "-";
                }
            },
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.release_date"
                ),
            formatExtraData: {
                locale,
            },
            sort: true,
        },
        {
            dataField: "commentary",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.commentary"
            ),
            formatter: (cell) => cell ?? "-",
            title: true,
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.commentary"
                ),
            classes: "custom-column",
            headerClasses: "custom-column",
        },
        {
            dataField: "support",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.support.head"
            ),
            formatter: (cell, row, rowIndex, { location }) => {
                if (cell) {
                    return I18n.getTranslation(
                        location,
                        `album.view.profileReleases.support.${cell.toLowerCase()}`
                    );
                } else {
                    return "-";
                }
            },
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.support.head"
                ),
            classes: "custom-column",
            headerClasses: "custom-column",
            formatExtraData: {
                location,
            },
        },
        {
            dataField: "c_line",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.cline"
            ),
            formatter: (cell) => cell ?? "-",
            title: true,
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.cline"
                ),
            classes: "custom-column-cpline",
        },
        {
            dataField: "p_line",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.pline"
            ),
            formatter: (cell) => cell ?? "-",
            title: true,
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.pline"
                ),
            classes: "custom-column-cpline",
        },
        {
            dataField: "links",
            text: I18n.getTranslation(
                location,
                "album.view.profileReleases.links"
            ),
            formatter: (cell) => (
                <Button
                    variant={cell.length === 0 ? "secondary" : "primary"}
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        setModalState({ show: true, links: cell });
                    }}
                    disabled={cell.length === 0}
                >
                    <FontAwesomeIcon icon={faLink} fixedWidth />
                </Button>
            ),
            headerTitle: () =>
                I18n.getTranslation(
                    location,
                    "album.view.profileReleases.links"
                ),
        },
    ];

    const selectRow = {
        mode: "radio",
        clickToSelect: true,
        classes: "table-info",
        hideSelectColumn: true,
        onSelect: ({ id }, isSelect) => {
            if (isSelect) {
                dispatchAlbum({
                    type: RELEASE_SELECTION_SET,
                    data: id,
                });
            }
        },
        selected: [profileSelection.releaseId],
    };

    const rowClasses = ({ id }) => {
        let classes = null;

        if (profileHighlights.releases.includes(id)) {
            classes = "table-warning";
        }

        return classes;
    };

    const defaultSorted = [
        {
            dataField: "release_date",
            order: "desc",
        },
        {
            dataField: "id",
            order: "asc",
        },
    ];

    return (
        <Releases
            {...props}
            {...state}
            columns={columns}
            selectRow={selectRow}
            rowClasses={rowClasses}
            defaultSorted={defaultSorted}
            modalState={modalState}
            setModalState={setModalState}
            location={location}
        />
    );
};

export default EnhancedReleases;
