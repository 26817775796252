import { default as Home } from "layout/Home";
import Plannings from "pages/Plannings";
import PlanningsManagement from "pages/PlanningsManagement";
import PlanningsPicture from "pages/PlanningsPicture";
import ChartsDisplay from "pages/ChartsDisplay";
import PlanningsBilling from "pages/PlanningsBilling";
import PlanningsStatistic from "pages/PlanningsStatistic";
import PlanningsClient from "pages/PlanningsClient";
import PartnerTops from "pages/PartnerTops";
import PicturesLabelGallery from "pages/PicturesLabelGallery";
import PicturesLabelStatus from "pages/PicturesLabelStatus";
import PicturesLabelValidation from "pages/PicturesLabelValidation";
import PicturesLabelRejected from "pages/PicturesLabelRejected";
import LabelPromoManagement from "pages/LabelPromoManagement";
import ArtistTitle from "pages/ArtistTitle";
import Recommendation from "pages/Recommendation";
import GenreView from "pages/GenreView";
import GenreManagement from "pages/GenreManagement";
import UserManagement from "pages/UserManagement";
import UserRoleManagement from "pages/UserRoleManagement";
import UserRightManagement from "pages/UserRightManagement";
import Country from "pages/Country";
import CountryManagement from "pages/CountryManagement";
import Role from "pages/Role";
import RoleManagement from "pages/RoleManagement";
import DataExternalInternationalsCharts from "pages/DataExternalInternationalsCharts";
import DataExternalDeezerTopsSongs from "pages/DataExternalDeezerTopsSongs";
import DataExternalUniversalArtist from "pages/DataExternalUniversalArtist";
import CNMAggregation from "pages/CNMAggregation";
import CNMConsolidation from "pages/CNMConsolidation";
import CNMRepositories from "pages/CNMRepositories";
import CNMCharts from "pages/CNMCharts";
import CNMReport from "pages/CNMReport";
import AppleSampleAssessment from "pages/AppleSampleAssessment";
import AppleSampleManagement from "pages/AppleSampleManagement";
import AppleSampleReport from "pages/AppleSampleReport";
import AuthorManagement from "pages/AuthorManagement";
import MoodmediaAdmin from "pages/MoodmediaAdmin";
import MoodmediaSearch from "pages/MoodmediaSearch";
import MoodmediaRecommendation from "pages/MoodmediaRecommendation";
import ArtistSearch from "pages/ArtistSearch";
import CurationFollowup from "pages/CurationFollowup";
import GettyFollowup from "pages/GettyFollowup";
import ArtistManagement from "pages/ArtistManagement";
import ArtistRedirect from "pages/ArtistRedirect";
import ArtistDelete from "pages/ArtistDelete";
import ArtistFollowup from "pages/ArtistFollowup";
import ArtistCreate from "pages/ArtistCreate";
import ChartsTranslation from "pages/ChartsTranslation";
import ChartsMatching from "pages/ChartsMatching";
import ArtistView from "pages/ArtistView";
import MiscEntity from "pages/MiscEntity";
import MiscEntityManagement from "pages/MiscEntityManagement";
import AlbumView from "pages/AlbumView";
import RecordingView from "pages/RecordingView";
import Profile from "pages/Profile";
import SnepFileManagement from "pages/SnepFileManagement";
import Search from "pages/Search";
import MatchingArtist from "pages/MatchingArtist";
import MatchingAlbum from "pages/MatchingAlbum";
import MatchingTrack from "pages/MatchingTrack";
import ApiMonitoring from "pages/ApiMonitoring";

const base = ":locale";
const routes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: base,
        element: <Home />,
    },
    {
        name: "Search",
        path: `${base}/search/:selectedTab?`,
        element: <Search />,
    },
    {
        name: "ChartsMatching",
        path: `${base}/matching/tops/:categoryId?/:chartId?`,
        element: <ChartsMatching />,
    },
    {
        name: "Plannings",
        path: `${base}/plannings`,
        element: <Plannings />,
    },
    {
        name: "PlanningsBilling",
        path: `${base}/plannings/billing`,
        element: <PlanningsBilling />,
    },
    {
        name: "PlanningsStatistic",
        path: `${base}/plannings/statistic`,
        element: <PlanningsStatistic />,
    },
    {
        name: "PlanningsClient",
        path: `${base}/plannings/client`,
        element: <PlanningsClient />,
    },
    {
        name: "PlanningsPicture",
        path: `${base}/plannings/picture/:planningId?`,
        element: <PlanningsPicture />,
    },
    {
        name: "PlanningsManagement",
        path: `${base}/plannings/:planningId/:partnerId/:selectedTab?`,
        element: <PlanningsManagement />,
    },
    {
        name: "ChartsDisplay",
        path: `${base}/charts/display/:categoryId?/:chartId?`,
        element: <ChartsDisplay />,
    },
    {
        name: "PartnerTops",
        path: `${base}/matching/partner_tops/:partner?/:date?/:top?`,
        element: <PartnerTops />,
    },
    {
        name: "PicturesLabelGallery",
        path: `${base}/pictures/label/gallery`,
        element: <PicturesLabelGallery />,
    },
    {
        name: "PicturesLabelStatus",
        path: `${base}/pictures/label/status`,
        element: <PicturesLabelStatus />,
    },
    {
        name: "PicturesLabelValidation",
        path: `${base}/pictures/label/validation`,
        element: <PicturesLabelValidation />,
    },
    {
        name: "PicturesLabelRejected",
        path: `${base}/pictures/label/rejected`,
        element: <PicturesLabelRejected />,
    },
    {
        name: "LabelPromoManagement",
        path: `${base}/label-promo`,
        element: <LabelPromoManagement />,
    },
    {
        name: "ArtistTitle",
        path: `${base}/tools/artist-title`,
        element: <ArtistTitle />,
    },
    {
        name: "Recommendation",
        path: `${base}/tools/recommendation`,
        element: <Recommendation />,
    },
    {
        name: "GenreView",
        path: `${base}/genre/view/:id_genre?`,
        element: <GenreView />,
    },
    {
        name: "GenreManagement",
        path: `${base}/genre/manage`,
        element: <GenreManagement />,
    },
    {
        name: "UserManagement",
        path: `${base}/user/manage`,
        element: <UserManagement />,
    },
    {
        name: "UserRoleManagement",
        path: `${base}/user/role/manage`,
        element: <UserRoleManagement />,
    },
    {
        name: "UserRightManagement",
        path: `${base}/user/right/manage`,
        element: <UserRightManagement />,
    },
    {
        name: "Country",
        path: `${base}/country`,
        element: <Country />,
    },
    {
        name: "CountryManagement",
        path: `${base}/country/manage`,
        element: <CountryManagement />,
    },
    {
        name: "Role",
        path: `${base}/role`,
        element: <Role />,
    },
    {
        name: "RoleManagement",
        path: `${base}/role/manage`,
        element: <RoleManagement />,
    },
    {
        name: "DataExternalInternationalsCharts",
        path: `${base}/data/external/internationals/charts`,
        element: <DataExternalInternationalsCharts />,
    },
    {
        name: "DataExternalDeezerTopsSongs",
        path: `${base}/data/external/deezer/top/songs`,
        element: <DataExternalDeezerTopsSongs />,
    },
    {
        name: "DataExternalUniversalArtist",
        path: `${base}/data/external/universal/artist`,
        element: <DataExternalUniversalArtist />,
    },
    {
        name: "CNMAggregation",
        path: `${base}/cnm/aggregation/:year?/:chartType?/:cnmTitleId?/:similarCnmTitleId?`,
        element: <CNMAggregation />,
    },
    {
        name: "CNMConsolidation",
        path: `${base}/cnm/consolidation/:year?/:chartType?/:platformId?/:titleId?`,
        element: <CNMConsolidation />,
    },
    {
        name: "CNMRepositories",
        path: `${base}/cnm/repositories/:repository?/:year?`,
        element: <CNMRepositories />,
    },
    {
        name: "CNMCharts",
        path: `${base}/cnm/charts/:type?/:id_pf_or_year?/:id_year?/:chartType?`,
        element: <CNMCharts />,
    },
    {
        name: "CNMReport",
        path: `${base}/cnm/report/:year?/:chartType?`,
        element: <CNMReport />,
    },
    {
        name: "AppleSampleAssessment",
        path: `${base}/apple/sample/assessment/:lyricSampleId`,
        element: <AppleSampleAssessment />,
    },
    {
        name: "AppleSampleManagement",
        path: `${base}/apple/sample/management/:sampleId?/:selectedTab?`,
        element: <AppleSampleManagement />,
    },
    {
        name: "AppleSampleReport",
        path: `${base}/apple/sample/report/:sampleId?/:selectedTab?`,
        element: <AppleSampleReport />,
    },
    {
        name: "AuthorManagement",
        path: `${base}/author/manage`,
        element: <AuthorManagement />,
    },
    {
        name: "MoodmediaAdmin",
        path: `${base}/moodmedia/admin`,
        element: <MoodmediaAdmin />,
    },
    {
        name: "MoodmediaSearch",
        path: `${base}/moodmedia/search`,
        element: <MoodmediaSearch />,
    },
    {
        name: "MoodmediaRecommendation",
        path: `${base}/moodmedia/recommendation`,
        element: <MoodmediaRecommendation />,
    },
    {
        name: "ArtistFollowup",
        path: `${base}/artist/followup`,
        element: <ArtistFollowup />,
    },
    {
        name: "ArtistSearch",
        path: `${base}/artist`,
        element: <ArtistSearch />,
    },
    {
        name: "CurationFollowup",
        path: `${base}/curation/followup`,
        element: <CurationFollowup />,
    },
    {
        name: "GettyFollowup",
        path: `${base}/getty/followup`,
        element: <GettyFollowup />,
    },
    {
        name: "ArtistCreate",
        path: `${base}/artist/create`,
        element: <ArtistCreate />,
    },
    {
        name: "ArtistManagement",
        path: `${base}/artist/:artistId/edit/:selectedTab?/:album?/:albumId?/:release?/:releaseId?/:track?/:trackId?/:recording?/:recordingId?`,
        element: <ArtistManagement />,
    },
    {
        name: "ArtistRedirect",
        path: `${base}/artist/:artistId`,
        element: <ArtistRedirect />,
    },
    {
        name: "ArtistDelete",
        path: `${base}/artist/:artistId/delete`,
        element: <ArtistDelete />,
    },
    {
        name: "ChartsTranslation",
        path: `${base}/charts/translation/:language?/:categoryId?/:chartId?`,
        element: <ChartsTranslation />,
    },
    {
        name: "ArtistView",
        path: `${base}/artist/:artistId/view/:selectedTab?`,
        element: <ArtistView />,
    },
    {
        name: "MiscEntity",
        path: `${base}/misc-entity`,
        element: <MiscEntity />,
    },
    {
        name: "MiscEntityManagement",
        path: `${base}/misc-entity/:miscEntityId/edit/:selectedTab?`,
        element: <MiscEntityManagement />,
    },
    {
        name: "AlbumView",
        path: `${base}/album/:albumId/view/:selectedTab?`,
        element: <AlbumView />,
    },
    {
        name: "RecordingView",
        path: `${base}/recording/:recordingId/view/:selectedTab?`,
        element: <RecordingView />,
    },
    {
        name: "Profile",
        path: `${base}/profile`,
        element: <Profile />,
    },
    {
        name: "SnepFileManagement",
        path: `${base}/snep/file`,
        element: <SnepFileManagement />,
    },
    {
        name: "MatchingArtist",
        path: `${base}/matching/artist/:partnerId?/:listId?`,
        element: <MatchingArtist />,
    },
    {
        name: "MatchingAlbum",
        path: `${base}/matching/album/:partnerId?/:listId?`,
        element: <MatchingAlbum />,
    },
    {
        name: "MatchingTrack",
        path: `${base}/matching/track/:partnerId?/:listId?`,
        element: <MatchingTrack />,
    },
    {
        name: "ApiMonitoring",
        path: `${base}/api/monitoring`,
        element: <ApiMonitoring />,
    },
    {
        name: "All",
        path: "*",
        element: <Home />,
    },
];

export default routes;
