export const getYear = (date) => {
    if (!date) {
        return;
    }

    return date.split("-")[0];
};

export const formatAndCleanDate = (date) => {
    if (!date) {
        return;
    }

    return date
        .split("-")
        .filter((r) => r !== "00")
        .join("-");
};

export const displayAsString = (date, locale) => {
    if (!date) {
        return;
    }

    const newDateFiltered = date.split("-").filter((r) => r !== "00");
    const newDateJoined = newDateFiltered.join("-");

    let options = { year: "numeric", timeZone: "utc" };

    if (newDateFiltered.length >= 2) {
        options.month = "long";
    }

    if (newDateFiltered.length >= 3) {
        options.day = "numeric";
    }

    return new Date(newDateJoined).toLocaleDateString(locale, options);
};
