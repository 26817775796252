import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import { Button } from "react-bootstrap";
import ImageCard from "./ImageCard";
import Loader from "shared/components/Loader";
import Create from "pages/ArtistManagement/Discography/Actions/Cover/Create";
import "./Cover.css";

const Cover = ({
    covers,
    selected,
    onSelect,
    onSubmit,
    onSuccess,
    isLoading,
    isSubmitting,
}) => {
    return (
        <Widget
            title="artist.management.disco.objects.album.cover.head"
            actions={<Create onSuccess={onSuccess} />}
            enableCollapse
        >
            <Widget.Body className="border-top pt-3">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="row">
                        <div
                            className="col border-right"
                            style={{ maxWidth: "152px" }}
                        >
                            <h5>
                                <I18n t="artist.management.disco.objects.album.cover.musicstory" />
                            </h5>
                            <ImageCard
                                url={covers.musicstory + "?r=" + Math.random()}
                            />
                        </div>
                        <div className="col">
                            <h5>
                                <I18n t="artist.management.disco.objects.album.cover.partner" />
                            </h5>
                            <div className="d-flex align-content-center flex-wrap">
                                {covers.partners?.map((url) => (
                                    <ImageCard
                                        key={url}
                                        url={url}
                                        selected={selected === url}
                                        onClick={() => onSelect(url)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="d-flex flex-row-reverse pb-3">
                    <Button
                        variant="success"
                        disabled={isSubmitting || isLoading || !selected}
                        onClick={
                            !isSubmitting && !isLoading && selected
                                ? onSubmit
                                : null
                        }
                        title={I18n.getTranslation(
                            location,
                            `artist.management.disco.objects.album.cover.update.title`
                        )}
                    >
                        <I18n t={`utils.button.edit`} />
                    </Button>
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default Cover;
