import React, { useState, useEffect, useCallback } from "react";
import Collaboration from "./Collaboration";
import { useLocation, useParams } from "react-router";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import useIntParams from "shared/hooks/useIntParams";

const schema = yup.object().shape({
    collaboration: yup
        .array()
        .of(
            yup.object().shape({
                artist: yup
                    .object()
                    .shape({
                        id: yup.number().required("utils.form.required"),
                        name: yup.string().required("utils.form.required"),
                    })
                    .required("utils.form.required"),
                start_date: yup
                    .string()
                    .trim()
                    .nullable()
                    .transform((value) => value || null)
                    .matches(/^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/)
                    .when("end_date", {
                        is: (end_date) => end_date,
                        then: () =>
                            yup.string().required("utils.form.required"),
                    })
                    .test(
                        "is-valid-date",
                        "utils.form.required",
                        (value, context) => {
                            if (value && context.parent.end_date) {
                                const splitStartDate = value.split("-");
                                const splitEndDate =
                                    context.parent.end_date.split("-");

                                if (splitStartDate[0] < splitEndDate[0]) {
                                    return true;
                                }
                                if (
                                    splitStartDate[0] === splitEndDate[0] &&
                                    splitStartDate[1] < splitEndDate[1]
                                ) {
                                    return true;
                                }
                                if (
                                    splitStartDate[0] === splitEndDate[0] &&
                                    splitStartDate[1] === splitEndDate[1] &&
                                    splitStartDate[2] <= splitEndDate[2]
                                ) {
                                    return true;
                                }
                                return false;
                            }
                            return true;
                        }
                    ),
                end_date: yup
                    .string()
                    .trim()
                    .nullable()
                    .transform((value) => value || null)
                    .matches(/^\d{4}-(0[0-9]|1[0-2])-(0[0-9]|[12][0-9]|3[01])$/)
                    .test(
                        "is-valid-date",
                        "utils.form.required",
                        (value, context) => {
                            if (context.parent.start_date && value) {
                                const splitStartDate =
                                    context.parent.start_date.split("-");
                                const splitEndDate = value.split("-");

                                if (splitStartDate[0] < splitEndDate[0]) {
                                    return true;
                                }
                                if (
                                    splitStartDate[0] === splitEndDate[0] &&
                                    splitStartDate[1] < splitEndDate[1]
                                ) {
                                    return true;
                                }
                                if (
                                    splitStartDate[0] === splitEndDate[0] &&
                                    splitStartDate[1] === splitEndDate[1] &&
                                    splitStartDate[2] <= splitEndDate[2]
                                ) {
                                    return true;
                                }
                                return false;
                            }
                            return true;
                        }
                    ),
                id_role: yup.number().required("utils.form.required"),
            })
        )
        .ensure(),
});

const EnhancedCollaboration = (props) => {
    const { api } = useAPI();
    const location = useLocation();
    const { locale } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { artistId } = useIntParams();
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            collaboration: [],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "collaboration",
    });

    const getCollaboration = useCallback(() => {
        let cancelled = false;

        setIsLoading(true);
        api.get(`artist/${artistId}/collaboration`)
            .then((response) => {
                if (!cancelled) {
                    reset({ collaboration: response });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (!cancelled) {
                    console.error(error);
                    toast.error(error.message);
                    reset();
                    setIsLoading(false);
                }
            });

        return () => {
            cancelled = true;
        };
    }, [artistId]);

    useEffect(getCollaboration, [getCollaboration]);

    const [roleOptions, setRoleOptions] = useState([]);
    const getRole = useCallback(() => {
        api.get(`role/${locale}`)
            .then((response) => {
                setRoleOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getRole, [getRole]);

    const onSubmit = (data) => {
        return api
            .post(
                `artist/${artistId}/collaboration`,
                { locale },
                data.collaboration
            )
            .then((response) => {
                reset({ collaboration: response });
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.collaboration.update.success`
                    )
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.collaboration.update.error`,
                        error.message
                    )
                );
            });
    };

    return (
        <Collaboration
            {...props}
            getCollaboration={getCollaboration}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            fields={fields}
            append={append}
            remove={remove}
            location={location}
            locale={locale}
            roleOptions={roleOptions}
        />
    );
};

export default EnhancedCollaboration;
