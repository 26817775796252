import React, { useState, useEffect } from "react";
import TaskForm from "./TaskForm";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import swal from "sweetalert";
import useAPI from "shared/hooks/useApi";
import { PLANNING_PARTNER } from "shared/constants/Planning/Partner";

const EnhancedTaskForm = ({
    planning,
    subtasks_type,
    subtasks_assignement,
    ms_artist,
    artist_name,
    album_title,
    track_title,
    category,
    top_id,
    lyrics_sample_id,
    reason,
    onSubmitSuccess,
    defaultLocale,
    subtaskGroupExcluded,
    planningId,
    partnerId,
    taskId,
    forceSubmit,
    ...props
}) => {
    const location = useLocation();
    const locale = defaultLocale ?? useParams().locale;
    const { api } = useAPI();
    const [errors, setErrors] = useState({
        planningId: [],
        ms_artist: [],
        subtasks: {},
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [task, setTask] = useState({
        id_planning: planning ?? null,
        ms_artist: ms_artist ?? null,
        artist_name: artist_name ?? null,
        album_title: album_title ?? null,
        track_title: track_title ?? null,
        category: category ?? null,
        classification: null,
        subtasks: [
            {
                id: null,
                type: subtasks_type ?? null,
                assignement: subtasks_assignement ?? null,
                status: null,
                key: 0,
            },
        ],
        reason: reason ?? null,
        top_id: top_id ?? null,
        lyrics_sample_id: lyrics_sample_id ?? null,
        partner_id: partnerId,
        nextSubtaskKey: 1,
    });

    useEffect(() => {
        let cancelled = false;

        if (taskId) {
            api.get(`plannings/${planningId}/tasks/${taskId}`, { locale })
                .then((response) => {
                    if (!cancelled) {
                        // Add a unique key to the subtasks. Using the subtasks'
                        // ids as a key is not possible since new subtasks do not
                        // have an id, and using the index is not possible since
                        // subtasks may be removed.
                        setTask({
                            ...response,
                            subtasks: response.subtasks.map((subtask, key) => ({
                                ...subtask,
                                key,
                            })),
                            nextSubtaskKey: response.subtasks.length,
                        });
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    if (!cancelled) {
                        setIsLoading(false);
                    }
                });
        } else {
            if (!cancelled) {
                setIsLoading(false);
            }
        }

        return () => {
            cancelled = true;
        };
    }, [locale, planningId, taskId, setTask]);

    const [planningsOptions, setPlanningsOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        if (!planningId || taskId) {
            api.get("plannings", { locale })
                .then((response) => {
                    if (!cancelled) {
                        setPlanningsOptions([
                            {
                                label: I18n.getTranslation(
                                    location,
                                    "plannings.manage.planning.building"
                                ),
                                options: response
                                    .filter(
                                        ({ status }) => status === "BUILDING"
                                    )
                                    .map((row) => {
                                        const label = [
                                            row.title,
                                            row.start_date,
                                            row.end_date,
                                            `(${row.nb_tasks_completed}/${row.nb_tasks})`,
                                        ];
                                        return {
                                            value: row.id,
                                            label: label
                                                .filter(Boolean)
                                                .join(" - "),
                                        };
                                    }),
                            },
                            {
                                label: I18n.getTranslation(
                                    location,
                                    "plannings.manage.planning.progress"
                                ),
                                options: response
                                    .filter(
                                        ({ status }) => status === "PROGRESS"
                                    )
                                    .map((row) => {
                                        const label = [
                                            row.title,
                                            row.start_date,
                                            row.end_date,
                                            `(${row.nb_tasks_completed}/${row.nb_tasks})`,
                                        ];
                                        return {
                                            value: row.id,
                                            label: label
                                                .filter(Boolean)
                                                .join(" - "),
                                        };
                                    }),
                            },
                        ]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }

        return () => {
            cancelled = true;
        };
    }, [setPlanningsOptions, locale, location, planningId, taskId]);

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/tasks/category`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setCategoriesOptions(
                        response.map(({ value }) => {
                            return { label: value, value };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, setCategoriesOptions]);

    const [classificationOptions, setClassificationOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        if (partnerId === PLANNING_PARTNER.EDITORIAL) {
            api.get(`plannings/tasks/classification`, { locale })
                .then((response) => {
                    if (!cancelled) {
                        setClassificationOptions(
                            response.map(({ value }) => {
                                return { label: value, value };
                            })
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }

        return () => {
            cancelled = true;
        };
    }, [locale, partnerId, setClassificationOptions]);

    const [subtaskType, setSubtaskType] = useState([]);
    const [subtaskTypeMap, setSubtaskTypeMap] = useState({});
    useEffect(() => {
        let cancelled = false;

        api.get(
            `plannings/tasks/tasks`,
            Object.entries({
                locale,
                partnerId,
                subtaskGroupExcluded,
            }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {})
        )
            .then((response) => {
                if (!cancelled) {
                    setSubtaskType(response);
                    setSubtaskTypeMap(
                        Object.fromEntries(
                            response.map((type) => [type.name, type.group])
                        )
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, setSubtaskType, setSubtaskTypeMap]);

    const tasksOptions = [...new Set(subtaskType.map((s) => s.group))].map(
        (group) => ({
            label: I18n.getTranslation(
                location,
                `plannings.tasks.subtasks.typeGroups.${group}`
            ),
            options: subtaskType
                .filter((type) => type.group === group)
                .map((type) => ({
                    value: type.name,
                    label: type.name,
                })),
        })
    );

    const [assignementOptions, setAssignementOptions] = useState([]);
    useEffect(() => {
        let cancelled = false;

        api.get(`plannings/tasks/assignement`, { locale })
            .then((response) => {
                if (!cancelled) {
                    setAssignementOptions(
                        response.map(({ id, name }) => {
                            return { label: name, value: id };
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });

        return () => {
            cancelled = true;
        };
    }, [locale, setAssignementOptions]);

    const [artistInformations, setArtistInformations] = useState([]);
    useEffect(() => {
        let cancelled = false;

        if (task.ms_artist) {
            api.get(`artist/${task.ms_artist.id}/editorialized`)
                .then((response) => {
                    if (response && !cancelled) {
                        setArtistInformations([
                            {
                                badges: [
                                    {
                                        label: response.nb_related
                                            .toString(10)
                                            .padStart(2, "0"),
                                        color:
                                            response.nb_related >= 10
                                                ? "success"
                                                : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.nbRelated"
                                        ),
                                    },
                                ],
                            },
                            {
                                badges: [
                                    {
                                        label: "FR",
                                        color: response.has_bio_fr
                                            ? response.is_bio_fr_reference
                                                ? "success-auto"
                                                : "success"
                                            : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.hasBioFR"
                                        ),
                                    },
                                    {
                                        label: "EN",
                                        color: response.has_bio_en
                                            ? response.is_bio_en_reference
                                                ? "success-auto"
                                                : "success"
                                            : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.hasBioEN"
                                        ),
                                    },
                                    {
                                        label: "DE",
                                        color: response.has_bio_de
                                            ? response.is_bio_de_reference
                                                ? "success-auto"
                                                : "success"
                                            : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.hasBioDE"
                                        ),
                                    },
                                    {
                                        label: "JA",
                                        color: response.has_bio_ja
                                            ? response.is_bio_ja_reference
                                                ? "success-auto"
                                                : "success"
                                            : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.hasBioJA"
                                        ),
                                    },
                                ],
                            },
                            {
                                badges: [
                                    {
                                        label: "Picture",
                                        color: response.has_pictures
                                            ? "success"
                                            : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.hasPicture"
                                        ),
                                    },
                                    {
                                        label: "Curation",
                                        color: response.has_curation
                                            ? "success"
                                            : "secondary",
                                        tooltip: I18n.getTranslation(
                                            location,
                                            "plannings.tasks.hasCuration"
                                        ),
                                    },
                                ],
                            },
                        ]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        } else {
            if (!cancelled) {
                setArtistInformations([]);
            }
        }

        return () => {
            cancelled = true;
        };
    }, [locale, location, setArtistInformations, task.ms_artist]);

    const handleSubmit = () => {
        setIsSubmitting(true);

        let processedErrors = Object.entries(task).reduce(
            (acc, curr) => {
                const [key, value] = curr;
                switch (key) {
                    case "id_planning":
                        if (!value && !planningId) {
                            acc[key] = [
                                I18n.getTranslation(
                                    location,
                                    "plannings.tasks.error.required"
                                ),
                            ];
                        }
                        break;
                    case "ms_artist":
                        if (
                            partnerId === PLANNING_PARTNER.EDITORIAL &&
                            !value
                        ) {
                            acc[key] = [
                                I18n.getTranslation(
                                    location,
                                    "plannings.tasks.error.required"
                                ),
                            ];
                        }
                        break;
                    case "artist_name":
                        if (
                            partnerId !== PLANNING_PARTNER.EDITORIAL &&
                            !value
                        ) {
                            acc[key] = [
                                I18n.getTranslation(
                                    location,
                                    "plannings.tasks.error.required"
                                ),
                            ];
                        }
                        break;
                    default:
                }
                return acc;
            },
            { id_planning: [], ms_artist: [] }
        );

        const groupsWithTooManyTasks = [
            ...new Set(subtaskType.map((s) => s.group)),
        ].filter(
            (group) =>
                task.subtasks.filter(
                    (subtask) => subtaskTypeMap[subtask.type] === group
                ).length > 1
        );
        processedErrors = {
            ...processedErrors,
            subtasks: Object.fromEntries(
                task.subtasks
                    .filter((subtask) =>
                        groupsWithTooManyTasks.includes(
                            subtaskTypeMap[subtask.type]
                        )
                    )
                    .map((subtask) => [
                        subtask.key,
                        I18n.getTranslation(
                            location,
                            `plannings.tasks.subtasks.error.oneTypePerGroup`,
                            I18n.getTranslation(
                                location,
                                `plannings.tasks.subtasks.typeGroups.${
                                    subtaskTypeMap[subtask.type]
                                }`
                            )
                        ),
                    ])
            ),
        };

        setErrors(processedErrors);

        if (
            processedErrors.id_planning.length !== 0 ||
            processedErrors.ms_artist.length !== 0 ||
            processedErrors.artist_name !== undefined ||
            Object.values(processedErrors.subtasks).length !== 0 ||
            Object.values(artistErrors).length !== 0
        ) {
            setIsSubmitting(false);
            return;
        }

        let apiRequest;
        if (taskId) {
            apiRequest = api.put(
                `plannings/${planningId}/tasks/${taskId}`,
                { locale },
                task
            );
        } else if (!planningId) {
            apiRequest = api.post(
                `plannings/${task.id_planning}/tasks/create`,
                { locale },
                task
            );
        } else {
            apiRequest = api.post(
                `plannings/${planningId}/tasks/create`,
                { locale },
                task
            );
        }

        apiRequest
            .then((response) => {
                toast.success(response.message);
                onSubmitSuccess?.();
                setIsSubmitting(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsSubmitting(false);
            });
    };

    useEffect(() => {
        if (forceSubmit) {
            handleSubmit();
        }
    }, []);

    const [artistErrors, setArtistErrors] = useState([]);
    const checkArtistSelected = (planningId, artistId) => {
        setArtistErrors([]);

        if (planningId && artistId) {
            api.get(`plannings/${planningId}/tasks/check/artist`, {
                locale,
                id: artistId,
            })
                .then((response) => {
                    const { errors, warnings } = response;

                    errors.forEach((message) => {
                        swal({
                            title: I18n.getTranslation(
                                location,
                                "utils.sweetalert.error"
                            ),
                            text: message,
                            icon: "error",
                            dangerMode: true,
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: I18n.getTranslation(
                                        location,
                                        "utils.sweetalert.ok"
                                    ),
                                    closeModal: true,
                                },
                            },
                        }).then((isConfirm) => {
                            if (isConfirm) {
                                setArtistErrors([...artistErrors, message]);
                            }
                        });
                    });

                    warnings.forEach((message) => {
                        swal({
                            title: I18n.getTranslation(
                                location,
                                "utils.sweetalert.warnings"
                            ),
                            text: message,
                            icon: "warning",
                            dangerMode: false,
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: I18n.getTranslation(
                                        location,
                                        "utils.sweetalert.ok"
                                    ),
                                    closeModal: true,
                                },
                            },
                        });
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                });
        }
    };

    return (
        <TaskForm
            {...props}
            handleSubmit={handleSubmit}
            planningId={parseInt(planningId, 10)}
            partnerId={parseInt(partnerId, 10)}
            taskId={parseInt(taskId, 10)}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            task={task}
            setTask={setTask}
            errors={errors}
            planningsOptions={planningsOptions}
            categoriesOptions={categoriesOptions}
            classificationOptions={classificationOptions}
            tasksOptions={tasksOptions}
            assignementOptions={assignementOptions}
            checkArtistSelected={checkArtistSelected}
            artistErrors={artistErrors}
            artistInformations={artistInformations}
            locale={locale}
        />
    );
};

export default EnhancedTaskForm;
