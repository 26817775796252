import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import Data from "./Data";

const EnhancedData = ({ catalog, ...props }) => {
    const [stats, setStats] = useState({
        total: "-",
        no_similar: "-",
        to_2_49_similar: "-",
        to_50_499_similar: "-",
        morethan_500_similar: "-",
    });
    const { api } = useAPI();
    const [isLoading, setIsLoading] = useState(true);

    const getStatistics = useCallback(() => {
        api.get(`moodmedia/statistics/${catalog}`)
            .then((response) => {
                setStats(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, [catalog]);

    useEffect(getStatistics, [getStatistics]);

    return <Data {...props} stats={stats} isLoading={isLoading} />;
};

export default EnhancedData;
