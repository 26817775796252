import React, { useState, useEffect, useCallback, useContext } from "react";
import useAPI from "shared/hooks/useApi";
import { useParams, useLocation } from "react-router";
import Cover from "./Cover";
import { toast } from "react-toastify";
import I18n from "shared/lib/I18n";
import {
    DiscographyContext,
    ALBUM_UPD,
    ALBUM_COVER_UP_TO_DATE,
} from "pages/ArtistManagement/Discography";

const EnhancedCover = (props) => {
    const { api } = useAPI();
    const { dispatchDiscography, albumCoverUpToDate } =
        useContext(DiscographyContext);
    const [selected, setSelected] = useState("");
    const [covers, setCovers] = useState({
        musicstory:
            "http://images.music-story.com/img/jaquette-defaut-album-400.jpg",
        partners: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { albumId, releaseId, locale } = useParams();
    const location = useLocation();

    const getCovers = useCallback(() => {
        if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}/covers`)
                .then((response) => {
                    setCovers(response);
                    dispatchDiscography({
                        type: ALBUM_COVER_UP_TO_DATE,
                        data: true,
                    });
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        }
    }, [albumId, releaseId]);

    useEffect(getCovers, [getCovers]);

    useEffect(() => {
        if (!albumCoverUpToDate) {
            getCovers();
        }
    }, [albumCoverUpToDate]);

    const onSelect = (url) => {
        setSelected(url === selected ? "" : url);
    };

    const onSubmit = () => {
        setIsSubmitting(true);
        api.post(`album/${albumId}/cover`, { locale }, { url: selected })
            .then(({ url }) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.cover.update.success`
                    )
                );
                setSelected("");
                setIsSubmitting(false);
                onSuccess(url);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.cover.update.error`,
                        error.message
                    )
                );
                setIsSubmitting(false);
            });
    };

    const onSuccess = (url) => {
        setCovers({ ...covers, musicstory: `${url}?${+new Date()}` });
        dispatchDiscography({
            type: ALBUM_UPD,
            data: {
                id: parseInt(albumId, 10),
                cover: `${url}?${+new Date()}`,
            },
        });
    };

    return (
        <Cover
            covers={covers}
            selected={selected}
            onSelect={onSelect}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            {...props}
        />
    );
};

export default EnhancedCover;
