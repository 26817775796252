import React from "react";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import "./Widget.css";

const Widget = ({
    title,
    titleRaw,
    titleArgs,
    subtitle,
    actions,
    enableCollapse,
    isCollapsed,
    setIsCollapsed,
    newChildren,
    className,
    headerClassName,
    style,
    headerStyle,
    isLoading,
    headerCenter,
}) => {
    return (
        <section className={clsx("widget", className)} style={style}>
            {isLoading && (
                <div className="loader" style={{ zIndex: "9999999" }}>
                    <Loader size="2x" />
                </div>
            )}
            {(title !== undefined ||
                actions !== undefined ||
                enableCollapse !== undefined ||
                headerCenter !== undefined) && (
                <header
                    className={clsx(
                        "d-flex justify-content-between align-items-center",
                        headerClassName
                    )}
                    style={headerStyle}
                >
                    {title && (
                        <div>
                            <h4 className="mr-auto">
                                {titleRaw ? (
                                    title
                                ) : (
                                    <I18n t={title} args={titleArgs} />
                                )}
                            </h4>
                            {subtitle && (
                                <div className="mr-auto">{subtitle}</div>
                            )}
                        </div>
                    )}

                    {headerCenter && (
                        <div className="ml-auto mr-auto">{headerCenter}</div>
                    )}
                    {actions && (
                        <div className="ml-auto d-flex align-items-center">
                            {actions}
                        </div>
                    )}
                    {enableCollapse && (
                        <div className="ml-3">
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={isCollapsed ? faChevronDown : faChevronUp}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            />
                        </div>
                    )}
                </header>
            )}
            {newChildren}
        </section>
    );
};

export default Widget;
