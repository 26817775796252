import React from "react";
import Discography from "./Discography";
import { DiscographyProvider } from "./Discography.provider";

const EnhancedDiscography = (props) => {
    return (
        <DiscographyProvider>
            <Discography {...props} />
        </DiscographyProvider>
    );
};

export default EnhancedDiscography;
