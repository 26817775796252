import React from "react";
import PlanningsProduction from "pages/PlanningsProduction";
import I18n, { Navigate } from "shared/lib/I18n";
import useUser from "shared/hooks/useUser";
import logo from "assets/static/images/MusicStory_Symbol_RGB.png";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

const Home = () => {
    const { user } = useUser();
    const location = useLocation();

    if (!user.hasRight("homepage.show")) {
        return <Navigate to="/search" replace />;
    }

    return (
        <div className="content-wrap">
            <Helmet>
                <title>{`${I18n.getTranslation(location, "dashboard")}`}</title>
            </Helmet>
            {user.hasRight("plannings.production") ? (
                <PlanningsProduction />
            ) : (
                <main
                    id="content"
                    className="content"
                    role="main"
                    style={{ height: "100%" }}
                >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <I18n t="dashboard" />
                        </li>
                    </ol>
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "calc(100% - 1.5rem - 18px)" }}
                    >
                        <div>
                            <div>
                                <img
                                    style={{ width: "325px" }}
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                            <div>
                                <h2 className="mt-3 text-center">
                                    <strong>
                                        <I18n t="logo" />
                                    </strong>
                                </h2>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
};

export default Home;
