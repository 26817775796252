import React, { useContext, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import { DiscographyContext } from "pages/ArtistManagement/Discography";
import Transfer from "./Transfer";

const EnhancedTransfer = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedTab, artistId, albumId, releaseId, locale } = useParams();
    const { releases } = useContext(DiscographyContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSuccess = (artistId, albumId) => {
        handleClose();
        navigate(
            `/${locale}/artist/${artistId}/edit/${selectedTab}/album/${albumId}/release/${releaseId}`
        );
    };

    const currentRelease = releases.find(
        (release) => release.id === parseInt(releaseId, 10)
    );

    return (
        <Transfer
            {...props}
            artistId={artistId}
            albumId={albumId}
            releaseId={releaseId}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            disabled={currentRelease?.main && releases.length !== 1}
            onSuccess={onSuccess}
            location={location}
        />
    );
};

export default EnhancedTransfer;
