import React, { useState } from "react";
import Statistics from "./Statistics";

const EnhancedStatistics = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Statistics
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            {...props}
        />
    );
};

export default EnhancedStatistics;
