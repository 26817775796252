import React from "react";
import I18n from "shared/lib/I18n";
import Widget from "shared/components/Widget";
import BootstrapTable from "@musicstory/react-bootstrap-table-next";
import "@musicstory/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, {
    Search,
} from "@musicstory/react-bootstrap-table2-toolkit";
import {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
} from "@musicstory/react-bootstrap-table2-paginator";
import clsx from "clsx";
import styles from "./HubbardArtist.module.css";
import MatchedFilter from "./Filter/MatchedFilter";
import CompletedFilter from "./Filter/CompletedFilter";
import CheckedFilter from "./Filter/CheckedFilter";
import SkipedFilter from "./Filter/SkipedFilter";
import Tippy from "@tippyjs/react";
import EditMatchingsModal from "shared/components/EditMatchingsModal";

const HubbardArtist = ({
    data,
    isLoading,
    columns,
    defaultSorted,
    pagination,
    rowClasses,
    locale,
    location,
    filter,
    setFilter,
    matchingsModalArtist,
    setMatchingsModalArtistID,
    onMatchingChange,
    fetchCandidates,
}) => {
    const { SearchBar } = Search;
    return (
        <>
            <EditMatchingsModal
                artistId={matchingsModalArtist?.id_musicbrainz}
                artistName={matchingsModalArtist?.name}
                matched={matchingsModalArtist?.artist !== null}
                show={matchingsModalArtist !== null}
                onHide={() => setMatchingsModalArtistID(null)}
                locale={locale}
                onChange={onMatchingChange}
                fetchCandidates={fetchCandidates}
            />
            <ToolkitProvider
                keyField="uniqId"
                data={data}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <PaginationProvider pagination={pagination}>
                        {({ paginationProps, paginationTableProps }) => (
                            <Widget
                                title={`matching.artist.artist.head`}
                                isLoading={isLoading}
                                actions={
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                }
                            >
                                <Widget.Body>
                                    <div className="row mb-3">
                                        <div className="col-11">
                                            <div className="row">
                                                <div className="col-3">
                                                    <SearchBar
                                                        {...toolkitprops.searchProps}
                                                        placeholder={I18n.getTranslation(
                                                            location,
                                                            "utils.select.placeholder"
                                                        )}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <MatchedFilter
                                                        value={filter.matched}
                                                        setFilter={setFilter}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <CompletedFilter
                                                        value={filter.completed}
                                                        setFilter={setFilter}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <CheckedFilter
                                                        value={filter.checked}
                                                        setFilter={setFilter}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <SkipedFilter
                                                        value={filter.skiped}
                                                        setFilter={setFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1 text-center">
                                            <Tippy
                                                content={
                                                    <ul
                                                        className={clsx(
                                                            "list-group",
                                                            styles.tippy
                                                        )}
                                                    >
                                                        <li className="list-group-item list-group-item-success">
                                                            <I18n t="matching.artist.artist.legend.completed" />
                                                        </li>
                                                        <li className="list-group-item list-group-item-warning ">
                                                            <I18n t="matching.artist.artist.legend.matched" />
                                                        </li>
                                                        <li className="list-group-item list-group-item-secondary">
                                                            <I18n t="matching.artist.artist.legend.skiped" />
                                                        </li>
                                                        <li className="list-group-item list-group-item-danger">
                                                            <I18n t="matching.artist.artist.legend.error" />
                                                        </li>
                                                    </ul>
                                                }
                                                arrow={false}
                                                trigger="click"
                                            >
                                                <button
                                                    className="btn btn-info"
                                                    type="button"
                                                >
                                                    <I18n t="matching.artist.artist.legend.title" />
                                                </button>
                                            </Tippy>
                                        </div>
                                    </div>
                                </Widget.Body>
                                <Widget.Body
                                    className={clsx(
                                        "widget-table-overflow overflow-auto border-top border-bottom",
                                        styles.body
                                    )}
                                >
                                    <BootstrapTable
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        bootstrap4
                                        hover
                                        striped
                                        bordered={false}
                                        noDataIndication={
                                            <I18n t="matching.artist.artist.empty" />
                                        }
                                        classes="table-header-fixed table-layout-auto mb-0"
                                        defaultSorted={defaultSorted}
                                        rowClasses={rowClasses}
                                    />
                                </Widget.Body>
                                <Widget.Body>
                                    <div className="row align-items-center mt-3">
                                        <>
                                            <div className="col">
                                                <PaginationTotalStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="col">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </>
                                    </div>
                                </Widget.Body>
                            </Widget>
                        )}
                    </PaginationProvider>
                )}
            </ToolkitProvider>
        </>
    );
};

export default HubbardArtist;
