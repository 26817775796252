import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Admin from "./Admin";
import { toast } from "react-toastify";
import useAPI from "shared/hooks/useApi";
import { useLocation, useParams } from "react-router";
import I18n from "shared/lib/I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const EnhancedAdmin = (props) => {
    const [catalog, setCatalog] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCatalogOptions, setSelectedCatalogOptions] = useState("db1");
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();

    const getCatalog = useCallback(() => {
        setIsLoading(true);
        api.get(`moodmedia/admin/${selectedCatalogOptions}`)
            .then((response) => {
                setCatalog(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
                setIsLoading(false);
            });
    }, [selectedCatalogOptions]);

    useEffect(getCatalog, [getCatalog]);

    const columns = [
        {
            dataField: "id",
            text: I18n.getTranslation(location, "moodmedia.admin.id"),
            headerStyle: {
                width: "10rem",
            },
            sort: true,
        },
        {
            dataField: "title",
            text: I18n.getTranslation(location, "moodmedia.admin.title"),
            sort: true,
        },
        {
            dataField: "artist",
            text: I18n.getTranslation(location, "moodmedia.admin.artist"),
            sort: true,
        },
        {
            dataField: "nb_similar",
            text: I18n.getTranslation(location, "moodmedia.admin.nb_similar"),
            headerStyle: {
                width: "10rem",
            },
            sort: true,
        },
        {
            dataField: "dummyLink",
            isDummy: true,
            text: I18n.getTranslation(location, "moodmedia.admin.link"),
            headerStyle: {
                width: "5rem",
            },
            align: "center",
            formatter: (cell, { id }) => (
                <Link
                    to={{
                        pathname: `/${locale}/moodmedia/recommendation/`,
                        search: `?mmTrackIds=${id}&catalog=${selectedCatalogOptions}`,
                    }}
                    target="_blank"
                    className="btn btn-primary btn-sm"
                >
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
                </Link>
            ),
            searchable: false,
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const options = {
        sizePerPage: 100,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        disablePageTitle: true,
        hideSizePerPage: true,
        paginationTotalRenderer: (from, to, size) => (
            <div className="ml-3 mb-3">
                {I18n.getTranslation(location, "utils.pagination.total", {
                    size,
                })}
            </div>
        ),
    };

    const catalogOptions = [
        {
            value: "db1",
            label: "db1",
        },
        {
            value: "db2",
            label: "db2",
        },
        {
            value: "db3",
            label: "db3",
        },
    ];

    return (
        <Admin
            {...props}
            catalog={catalog}
            columns={columns}
            isLoading={isLoading}
            options={options}
            catalogOptions={catalogOptions}
            selectedCatalogOptions={selectedCatalogOptions}
            setSelectedCatalogOptions={setSelectedCatalogOptions}
            defaultSorted={defaultSorted}
        />
    );
};

export default EnhancedAdmin;
